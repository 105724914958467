<template>
    <div class="pageMain">
        <div class="tableSearch">
            <div class="fromHeader">
                <div>文本列表</div>
                <!--弹出窗-->
                <div class="popup">
                    <el-dialog title="创建文本" :visible.sync="dialogFormVisible">
                        <div class="scroll" ref="scroll">
                            <el-form :model="form" :rules="rules" status-icon :inline-message="true" ref="form">
                                <el-form-item label="标题" :label-width="formLabelWidth" prop="name" >
                                    <el-input v-model="form.name" autocomplete="off" placeholder="请输入文本标题" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="项目类型" :label-width="formLabelWidth" prop="type" class="projectSelect">
                                    <el-select v-model="form.type" placeholder="请选择项目类型">
                                        <el-option
                                                v-for="item in typeArr"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="格式" :label-width="formLabelWidth">
                                    <el-radio v-model="format" label="1">富文本</el-radio>
                                    <el-radio v-model="format" label="0">普通文本</el-radio>
                                </el-form-item>
                                <div class="richText" v-if="dialogFormVisible">
                                    <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
                                        <el-form   v-if="format=='1'">
                                            <template>
                                                <div style="border: 1px solid #ccc;">
                                                    <Toolbar
                                                            style="border-bottom: 1px solid #ccc"
                                                            :editor="editor"
                                                            :defaultConfig="toolbarConfig"
                                                            :mode="mode"
                                                    />
                                                    <Editor
                                                            style="height: 320px; overflow-y: hidden;width: 100%"
                                                            v-model="form.content"
                                                            :defaultConfig="editorConfig"
                                                            :mode="mode"
                                                            @onCreated="onCreated"
                                                            @onFocus="onFocus"

                                                    />
                                                </div>
                                            </template>
                                        </el-form>
                                        <el-input
                                                v-if="format=='0'"
                                                type="textarea"
                                                v-model="form.content"
                                                show-word-limit
                                                clearable
                                        >
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="submitForm('form')">
                                    创建
                                </el-button>
                                <el-button @click="resetForm('form')">
                                    重置
                                </el-button>
                            </div>
                        </div>
                    </el-dialog>
                </div>
                <div class="popup">
                    <el-dialog title="编辑" :visible.sync="dialogFormVisible2">
                        <div class="scroll" ref="scroll">
                            <el-form :model="form2" :rules="rules2" status-icon :inline-message="true" ref="form2">
                                <el-form-item label="标题" :label-width="formLabelWidth" prop="name">
                                    <el-input v-model="form2.name" autocomplete="off" placeholder="请输入文本标题" clearable disabled readonly></el-input>
                                </el-form-item>
                                <el-form-item label="项目类型" :label-width="formLabelWidth" prop="type" class="projectSelect">
                                    <el-select v-model="form2.type" placeholder="请选择项目类型">
                                        <el-option
                                                v-for="item in typeArr"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="格式" :label-width="formLabelWidth">
                                    <el-radio v-model="format" label="1">富文本</el-radio>
                                    <el-radio v-model="format" label="0">普通文本</el-radio>
                                </el-form-item>
                                <div class="richText"   v-if="dialogFormVisible2">
                                    <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
                                        <el-form   v-if="format=='1'">
                                            <template>
                                                <div style="border: 1px solid #ccc;">
                                                    <Toolbar
                                                            style="border-bottom: 1px solid #ccc"
                                                            :editor="editor"
                                                            :defaultConfig="toolbarConfig"
                                                            :mode="mode"
                                                    />
                                                    <Editor
                                                            style="height: 320px; overflow-y: hidden;width: 100%"
                                                            v-model="form2.content"
                                                            :defaultConfig="editorConfig"
                                                            :mode="mode"
                                                            @onCreated="onCreated"
                                                            @onFocus="onFocus"
                                                    />
                                                </div>
                                            </template>
                                        </el-form>
                                        <div class="commonText">
                                            <el-input
                                                    v-if="format=='0'"
                                                    type="textarea"
                                                    v-model="form2.content"
                                                    show-word-limit
                                                    clearable
                                            >
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                </div>
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="submitForm2('form2')">
                                    保存
                                </el-button>
                            </div>
                        </div>
                    </el-dialog>
                </div>
                <!--          弹出窗-->
                <div class="clickAddClass"  @click="createRuleBtn()">
                    <button>
                        <img src="../../../../assets/account/bi.png" alt=""><div>立即创建</div>
                    </button>
                </div>
            </div>
            <div class="fromLabels">
                <div class="labelLi">
                    <div class="labelLi_name">标题</div>
                    <template>
                        <el-input
                                placeholder="请输入标题"
                                v-model="tableReq.name"
                                @change="changeSearchTitle"
                                clearable>
                        </el-input>

                    </template>
                </div>
                <div class="labelLi">
                    <div class="labelLi_name">类型</div>
                    <template>
                        <el-select v-model="tableReq.type" placeholder="请选择项目类型" clearable>
                            <el-option
                                    v-for="item in typeArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </div>
                <div class="labelLi">
                    <div class="labelLi_name">创建时间</div>
                    <div class="creatTime">
                        <template>
                            <el-date-picker
                                    prefix-icon=" "
                                    v-model="searchBody.time"
                                    type="daterange"
                                    range-separator="-"
                                    @change="changeSearchTime"
                                    value-format="timestamp"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                    </div>
                </div>
                <div class="btnLabelLI">
                    <button @click="searchEndBtn">
                        <img src="../../../../assets/button/搜索icon.png" alt=""><div style="margin-left: 8px">搜索</div>
                    </button>
                    <button @click="searchEndReset">
                        <img src="../../../../assets/button/重置icon.png" alt=""><div style="margin-left: 8px">重置</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="tableMain">
            <div class="tableBox">
                <div class="scroll">
                    <template>
                        <el-table
                                :data="tableData.data"
                                style="width: 100%"
                                :header-cell-style="{ background: '#F5F6FA', textAlign: 'center' }"
                                :cell-style="{ textAlign: 'center' }"
                                empty-text="暂无数据"
                                element-loading-text="拼命加载中"
                                height="100%">
                            <el-table-column prop="" label="序号" sortable width="100">
                                <template slot-scope="scope">
                                    {{scope.$index+1+(tableReq.pageSize)*(tableReq.pageNo-1)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="标题" sortable>
                            </el-table-column>
                            <el-table-column prop="content" label="内容" sortable>
                                <template  slot-scope="scope">
                                    <el-tooltip  placement="top-start"  effect="light" popper-class="myTooltip" >
                                        <div slot="content" v-html="scope.row.content"></div>
                                        <div v-html="scope.row.content" class="contentLi"></div>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="项目类型" min-width="160" sortable>
                                <template slot-scope="scope">
                                    {{scope.row.type?getProjectType(scope.row.type):'--'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" min-width="160" sortable>
                                <template slot-scope="scope">
                                    {{scope.row.createTime?$iApi.moment(scope.row.createTime,'YYYY-MM-DD HH:mm:ss'):''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="operate" label="操作" width="140">
                                <template slot-scope="scope">
                                    <el-button @click="details(scope.row)" type="text" size="small" >编辑</el-button>
                                    <el-button @click="rowDelete(scope.row)" type="text" size="small" >删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
                <!--          分页标签-->
                <div class="paging">
                    <el-pagination
                            background
                            @size-change="changePageSize"
                            @current-change="changePageNo"
                            :current-page="tableReq.pageNo"
                            :page-sizes="[10, 20, 30, 50]"
                            :page-size="tableReq.pageSize"
                            layout="total, prev, pager, next, sizes"
                            :total="tableData.count">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    import {getRuleList,addRuleLi,alterRuleLi,delRuleLi} from  '../../../../api/workbenche'
    import {uploadApi} from '../../../../api/public'
    export default {
        components: { Editor, Toolbar },
        name: "ruleManageDefault",
        data() {
            return {
                searchBody:{//搜索数据包 //表格数据请求体
                    searFlag:false,//筛选条件表格页数跳回1效果处理
                    time:'',
                },
                tableReq:{
                    type:'',
                    name:'',
                    startTime:'',
                    endTime:'',
                    pageNo:1,
                    pageSize:20
                },
                options: [{
                    value: '1',
                    label: '选项1'
                }],
                tableData: [],
                dialogFormVisible: false,
                dialogFormVisible2:false,
                currentPage:1,
                companyId:[],
                form: {
                    type:'',
                    name:'',
                    content:'',
                    createTime:'',
                },
                form2: {
                    id:'',
                    name:'',
                    content:'',
                },
                typeArr:[
                    {value:1,label:'易用灵工'},
                    {value:2,label:'易用众包'},
                    {value:3,label:'光速领钱'},
                    {value:4,label:'光速发薪'},
                ],
                rules: {
                    name: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                    type:[
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ]
                },
                rules2: {
                    name: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                    type:[
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ]
                },
                formLabelWidth: '80px',
                format:'1',//文本格式
                //富文本属性
                editor: null,
                html: '<p>请输入内容</p>',
                toolbarConfig: { },
                editorConfig: {
                    placeholder: '请输入内容...',
                    MENU_CONF: {
                        uploadImage: {
                            // server:放自己公司后端提供的图片上传接口
                            server: '/api/upload/img',
                            // fieldName:自己公司和后端共同约定好的接收图片接口参数名
                            fieldName: 'file',
                            // 空就是所有类型
                            allowedFileTypes: ['image/*'],
                            // 拼接参数到地址上
                            metaWithUrl: false,
                            // 文件大小最大限制
                            maxFileSize: 1 * 1024 * 1024,//1M
                            // 请求头
                            headers: {token:''},
                            //自定义插入方法
                            // customInsert(result, insertFn) {
                            //     console.log(result,insertFn)
                            //     //把完整地址传进去
                            //     insertFn(result.data)
                            // }
                            //自定义上传方法
                            customUpload:async  (file, insertFn)=> {
                                const formData = new FormData();
                                formData.append('file',file)
                                if(file.size>1*1024*1024){
                                    return this.$message.error('图片大小不得超过1M')
                                }
                                uploadApi(formData).then(res=>{
                                    if(res.code==1000){
                                        insertFn(res.data)
                                    }else{
                                        this.$message.error('上传失败')
                                    }
                                }).catch(rej=>{
                                    if(rej.response.status==413){
                                        return this.$message.error('图片大小超出限制')
                                    }
                                })
                            },
                        },
                    },
                },
                mode: 'default', // or 'simple'
                loading:false
            };
        },
        filters:{
            filtersText(val){
                return val.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
            }
        },
        created() {
            this.getTableData()
        },
        methods:{
            //项目类型转换
            getProjectType(id){
                if(id){
                    id = Number(id)
                    return this.typeArr.filter(item=>item.value==id)[0].label
                }
                return '--'
            },
            //wangEditor创建周期
            onCreated(editor) {
                this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            },
            //wangEditor获焦周期
            onFocus(editor) {
                let TOKEN=sessionStorage.getItem('TOKEN')
                this.editorConfig.MENU_CONF.uploadImage.headers.token=TOKEN&&TOKEN.length?TOKEN.substring(1,TOKEN.length-1):''
            },
            //创建规则按钮
            createRuleBtn(){
                this.dialogFormVisible = true
            },
            changeSearchTitle(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
            },
            changeSearchTime(v){//搜索时间change
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
                if(!v){
                    this.tableReq.startTime=''
                    this.tableReq.endTime=''
                    this.searchBody.searFlag=true
                    return
                }
                let arr = this.$iApi.timeValStrHandle(v)
                this.tableReq.startTime=arr[0]
                this.tableReq.endTime=arr[1]
            },
            //页条数量
            changePageSize(v){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.tableReq.pageSize=v
                this.tableReq.pageNo=1
                this.getTableData()
            },
            //页码数量
            changePageNo(v){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.tableReq.pageNo=v
                this.getTableData()
            },
            searchEndBtn(){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.getTableData()
            },
            searchEndReset(){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.searchBody={}
                this.tableReq.name=''
                this.tableReq.startTime=''
                this.tableReq.endTime=''
                this.tableReq.type=''
                this.searchEndBtn()
            },
            details(row){
                this.dialogFormVisible2=true
                let {name,content,id,type} = row
                this.form2={id,name:name,content:content,type:type?Number(type):''}
            },
            rowDelete(row) {
                this.$confirm('是否确定删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delRuleLi(row.id).then(res=>{
                        if(res.code==1000){
                            this.getTableData().then(res=>{
                                if(!res.data.data.length){
                                    this.tableReq.pageNo=1
                                    this.getTableData()
                                }
                            })
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }else{
                            this.$message.error('删除失败')
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            //获取表格数组数据
            getTableData(){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                if(this.searchBody.searFlag){
                    this.tableReq.pageNo=1
                    this.searchBody.searFlag=false
                }
                return new Promise(resolve => {
                    getRuleList(this.tableReq).then(res=>{
                        resolve(res)
                        this.$loading().close()
                        if(res.code==1000){
                            this.tableData=res.data
                        }
                    })
                })
            },
            //表单提交//表单 立即创建按钮事件
            submitForm(formName){
                this.form.createTime=new Date().getTime()
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let flag = true
                        Object.keys(this.form).forEach(item=>{
                            if(this.form[item]==''||this.form[item]=='<p><br></p>'){
                                flag = false
                            }
                        })
                        if(flag){
                            // this.form.type=Number(this.form.type)
                            addRuleLi(this.form).then(res=>{
                                if(res.code==1000){
                                    this.getTableData()
                                    this.dialogFormVisible=false
                                    this.resetForm('form')
                                }else{
                                    this.$message.error(res.message);
                                }
                            })
                        }else{
                            this.$message.error('表单内容不能留空');
                        }
                    } else {
                        this.$message.error('表单内容不能留空');
                        return false;
                    }
                });
            },
            //    表单重置
            resetForm(formName) {
                this.form={
                    type:'',
                    name:'',
                    content:'',
                    createTime:'',
                }
                this.$refs[formName].resetFields();
            },
            //编辑 表单 保存
            submitForm2(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let flag = true
                        Object.keys(this.form2).forEach(item=>{
                            if(this.form2[item]==''||this.form2[item]=='<p><br></p>'){
                                flag = false
                            }
                        })
                        if(flag){
                            alterRuleLi(this.form2).then(res=>{
                                if(res.code==1000){
                                    this.getTableData()
                                    this.dialogFormVisible2=false
                                    this.resetForm2()
                                    this.$message.success('修改成功')
                                }else{
                                    this.$message.error('修改失败');
                                }
                            })
                        }else{
                            this.$message.error('表单内容不能留空');
                        }
                    } else {
                        this.$message.error('表单内容不能留空');
                        return false;
                    }
                });
            },
            //表单重置
            resetForm2() {
                Object.keys(this.form2).forEach(item=>{
                    this.form2[item]=''
                })
            }


        },
        mounted() {
            // 模拟 ajax 请求，异步渲染编辑器
            setTimeout(() => {
                this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
            }, 1500)
        },
        beforeDestroy() {
            const editor = this.editor
            if (editor == null) return
            editor.destroy() // 组件销毁时，及时销毁编辑器
        },
    };
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
    .pageMain {
        width: 100%;
        height: 100%;
        padding: 16px 19px 0 18px;
        box-sizing: border-box;
    }
    .tableSearch {
        width: 100%;
        padding: 18px 26px 0px 35px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
        height: 154px;
        opacity: 1;
        border-radius: 13px;
    }
    .fromLabels {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .el-select {
        width: 240px;
        height: 31px;
        opacity: 1;
        box-sizing: border-box;
    }
    ::v-deep .el-input__inner {
        height: 31px;
        line-height: 29px;
        border-radius: 4px;
    }
    ::v-deep  .el-select .el-input .el-select__caret {
        line-height: 31px;
    }
    .el-input__inner {
        background: #f4f4f5;
    }
    ::v-deep  .el-select .el-input .el-select__caret {
        color: rgba(100, 102, 107, 1);
    }
    .labelLi {
        margin-right: 12px;
        position: relative;
    }
    .btnLabelLI {
        margin-left: 48px;
    }
    .btnLabelLI > button:hover {
        cursor: pointer;
    }
    .btnLabelLI {
        width: 193px;
        height: 35px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 36px;
    }
    .clickAddClass {
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 28px;
        cursor: pointer;
    }
    .fromHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /*______________________________________*/
    .tableMain {
        width: 100%;
        height: calc(100% - 154px);
        background: #f5f6fa;
        box-sizing: border-box;
    }
    .tableBox {
        width: 100%;
        height: 100%;
        background: white;
    }
    ::v-deep .el-table tr {
        background: white;
    }
    ::v-deep .el-table th.el-table__cell {
        /*background: transparent;*/
    }
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
    }
    ::v-deep  .el-table .sort-caret.ascending {
        border-bottom-color: #409eff;
        top: 5px;
    }
    ::v-deep  .el-table .sort-caret.descending {
        border-top-color: #409eff;
        bottom: 7px;
    }
    ::v-deep  .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #c0c4cc;
    }
    ::v-deep  .el-table .descending .sort-caret.descending {
        border-top-color: #c0c4cc;
    }
    .labelLi_name {
        position: absolute;
        top: 7px;
        left: 16px;
        z-index: 2;
        color: rgba(138, 139, 145, 1);
        font-size: 12px;
    }
    ::v-deep .fromLabels .el-input__inner {
        padding-left: 75px !important;
        box-sizing: border-box;
        font-size: 12px;
    }
    .bihua {
        position: absolute;
        left: 0;
        bottom: -1px;
    }
    .el-select-dropdown__item {
        text-align: center;
        /*padding-left: 50px;*/
    }
    /*创建账号属性*/
    ::v-deep  .el-dialog{
        border-radius: 10px;
        margin-top: 10vh !important;
    }
    ::v-deep  .popup .el-dialog__title{
        width: 64px;
        height: 24px;
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        margin-left: 40px;
    }
    ::v-deep  .popup .el-dialog__title:before{
        content:'';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background:rgba(31, 120, 255, 1)  ;
        position: absolute;
        top: 26px;
        left: 40px;
    }
    ::v-deep  .popup .el-dialog__body{
        padding: 10px 20px;
    }
    ::v-deep  .popup .el-input{
        width: 50%;
    }
    ::v-deep  .popup .el-form-item__label{
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        vertical-align: top;
    }
    ::v-deep  .popup .el-form-item{
        margin-bottom: 15px;
    }
    ::v-deep  .popup .el-col-1{
        margin: 0 5px;
        text-align: center;

    }
    ::v-deep  .popup .el-col-2{
        width: 1px;
    }
    ::v-deep  .popup .el-col-11{
        width: 120px;
    }
    ::v-deep  .popup .el-input__prefix{
        display: none;
    }
    ::v-deep  .popup .el-input--prefix .el-input__inner{
        padding: 0;
        padding-left: 10px !important;
    }

    /*  按钮*/
    .btnLabelLI>button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btnLabelLI img{
        width: 13px;
    }
    .btnLabelLI>button:nth-of-type(1){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid  rgba(73, 119, 252, 1);
    }
    .btnLabelLI>button:nth-of-type(2){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 0.5px solid rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(73, 119, 252, 1);
    }
    .shouquanshijian .el-input__inner{
        background: white;
    }
    .shouquanshijian .el-range-editor.el-input__inner{
        border-radius:4px;
    }
    /*创建表单发布重置按钮居中*/
    .dialog-footer{
        text-align: center;
    }

    ::v-deep  .popup .el-button:nth-of-type(1){
        background-size: cover;
        border-radius: 5px;
        color: white;
        width: 265px;
        height: 61px;
        opacity: 1;
        background: rgba(31, 120, 255, 1);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    ::v-deep  .popup .el-button:nth-of-type(2){
        color: rgba(31, 120, 255, 1);
        border-radius: 5px;
        margin-left: 38px;
        width: 205px;
        height: 61px;
        opacity: 1;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    /*创建表单局部滚动*/
    ::v-deep  .popup .scroll{
        max-height: 75vh;
        overflow-y: auto;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
    /*创建账号*/
    .clickAddClass>button{
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid rgba(73, 119, 252, 1) ;
        cursor: pointer;
    }
    .clickAddClass img{
        width: 15px;
        height: 13.83px;
        opacity: 1;
        margin-right: 7px;
    }
    /*分页bug解决*/
    .paging{
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    ::v-deep  .paging .el-input__inner{
        padding: 0 15px !important;
    }
    ::v-deep  .scroll .el-table .el-table__cell{
        padding: 4px 0 !important;
    }
    ::v-deep  .bangdingqiye .el-input__inner{
        padding-left: 15px !important;
    }
    ::v-deep  .bangdingqiye .el-select{
        width: 50% !important;
    }
    ::v-deep  .bangdingqiye .el-input{
        width: 100%;
    }
    ::v-deep  .el-select__tags{
        top: 15%;
    }
    /*表格最后一列小白块处理*/
    ::v-deep  .el-table th.el-table__cell{
        background: rgb(245, 246, 250);
    }
    /*    富文本*/
    ::v-deep  .el-form{
        width: 100%;
        box-sizing: border-box;
    }

    ::v-deep  .richText .commonText .el-textarea__inner{
        height: 350px;
    }
    .el-input__inner{
        background: white;
    }
    ::v-deep  .creatTime .el-range-separator{
        line-height: 22px;
    }
    ::v-deep   .textType .el-input{
        width: 100% !important;
    }
    ::v-deep   .textType .el-input__inner{
        padding-left: 15px !important;
    }
    .contentLi{
        max-height: 50px;
        overflow: auto;
    }
    .contentLi::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 6px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
    .contentLi::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius   : 50px;
        background-color: #409eff;
        background-image: -webkit-linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent
        );
        display: none;
    }
    .contentLi  ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background   : #ededed;
        border-radius: 10px;
    }
    ::v-deep  .richText .el-form-item__content{
        padding-right: 80px;
        color: #50a5f1;
    }
    ::v-deep .popup .projectSelect .el-input{
        width: 100%;
    }
</style>
<!--创建时间-->
<style scoped>
    ::v-deep  .creatTime .el-date-editor .el-range__close-icon{
        line-height: 25px !important;
    }
</style>
<!--搜索区清空icon-->
<style scoped>
    ::v-deep  .fromLabels .el-input__suffix{
        display: flex;
        align-items: center;
    }
</style>
