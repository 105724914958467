<template>
  <div class="pageMain">
    <div class="tableMain">
      <ETable ref="ETable" :tableData="tableData"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
        <template #operate="{rowData:rowData}">
          <el-button type="text" size="small" @click="btn(rowData)">启用</el-button>
          <el-button type="text" size="small" @click="btn(rowData)">编辑</el-button>
        </template>
      </ETable>
    </div>
  </div>
</template>

<script>
  export default {
    name: "end",
    components:{
    },
    data() {
      return {
        //表格列表请求体
        listReq:{
          pageSize:20,
          pageNo:1,
          year:'',
          data:{
            teamid:'',
            status:'',
          }
        },
        tableData: {
          currentPage:0,
          data: [{'*':'*'}],
          cols:[
            {label:'付费等级',prop:'*'},
            {label:'付费规则',prop:'*'},
            {label:'状态',prop:'*'},
            {label:'会员数',prop:'*'},
            {label:'创建时间',prop:'*'},
          ],
        },
      };
    },
    created() {
    },
    methods:{
      handleSizeChange(val) {
        this.listReq.pageSize=val
        this.listReq.pageNo=1
        this.$iApi.paging(this.getList)
      },
      handleCurrentChange(val) {
        this.listReq.pageNo=val
        this.$iApi.paging(this.getList)
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableMain{
    width: 100%;
    height: 100%;
  }
</style>
