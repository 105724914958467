<template>
    <div>
        <el-dialog
                title="续费会员"
                custom-class="dialogClass"
                :visible.sync="show"
                width="30%">
            <el-form :model="form" :rules="rules"  ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <div class="formNode">
                    <el-form-item label="会员等级" prop="data.level">
                        <el-select v-model="form.data.level" filterable placeholder="请选择会员等级" disabled size="small" @change="vipGradeChange">
                            <el-option :label="item.name"
                                       :value="{id:item.id,value:item.id,name:item.name,label:item.name,contracttotal:item.contracttotal,contractunit:item.contractunit,
                                   recharge1:item.recharge1,recharge2:item.recharge2,recharge3:item.recharge3,levelremark:item.remark}"
                                       v-for="item in baseList.lvList" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="name">
                        <div style="height: 250px">
                            <ETable :tableData="tableData" ref="ETable" :cellClass="cellClass">
                                <template #day="{rowData:rowData}">
                                    {{rowData.day?rowData.day+'天':'--'}}
                                </template>
                                <template #money="{rowData:rowData}">
                                    {{rowData.money?rowData.money+'元':'--'}}
                                </template>
                            </ETable>
                        </div>
                    </el-form-item>
                    <el-form-item label="合同单价" prop="data.contractunit">
                        <div style="width:100%;display:flex;align-items:center">
                            <div style="width: 40%">
                                <el-input v-model="form.data.contractunit" size="small" placeholder="请输入合同单价" maxlength="30" disabled></el-input>
                            </div>
                            <div style="margin-left: 5px">元/份</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="套餐赠送合同量" prop="data.contracttotal">
                        <div style="width:100%;display:flex;align-items:center">
                            <div style="width: 40%">
                                <el-input v-model="form.data.contracttotal" size="small" placeholder="回显套餐赠送合同量" maxlength="30" disabled></el-input>
                            </div>
                            <div style="margin-left: 5px">份</div>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submitBtn">保存</el-button>
<!--                <el-button type="primary" @click="resetForm">重置</el-button>-->
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import rules from "../../../../../util/js/formRules";
    import {addVipAccRenew} from "../../../../../api/workbenche";
    export default {
        data() {
            return {
                show: false,
                tableData:{
                    data:[],
                    cols:[
                        {label:'有效期',prop:'day'},
                        {label:'价格',prop:'money'},
                    ],
                    paging:0,
                    operate:0,
                    selection:1,
                    selectionRadio:true,
                    serial:0,
                },
                baseList:{
                    lvList:[],
                },
                form:{
                    data:{
                        id:'',
                        userlevelid:'',
                        days:'',
                        level:'',
                        levelname:'',
                        levelremark:'',
                        contracttotal:'',
                        contractunit:'',
                    }
                },
                rules:{
                    "data1.level":rules(),
                    "data1.contracttotal":rules(),
                    "data1.contractunit":rules(),
                },
            };
        },
        created() {

        },
        methods: {
            //会员等级数据处理
            vipGradeChange(v){
                const {id,name,contracttotal,contractunit,recharge1,recharge2,recharge3,levelremark} = v
                this.form.data.contractunit=contractunit
                this.form.data.contracttotal=contracttotal
                this.form.data.levelname=name
                this.form.data.levelremark=levelremark
                const getRechargeList = [JSON.parse(recharge1),JSON.parse(recharge2),JSON.parse(recharge3)]
                this.tableData.data=getRechargeList.filter(item=>item.status===1)
            },
            submitBtn(){
                this.$refs['ruleForm'].validate(valid=>{
                    this.form.data.days=Number(this.$refs['ETable'].multipleSelection?.map(item=>item.day))
                    if(valid){
                        this.$ELoading()
                        const req = {
                            id:this.form.data.id,
                            userlevelid:this.form.data.userlevelid,
                            days:this.form.data.days,
                            // levelname:this.form.data.levelname,
                            // levelremark:this.form.data.levelremark,
                            // contracttotal:this.form.data.contracttotal,
                            // contractunit:this.form.data.contractunit,
                        }
                        addVipAccRenew(this.$req(req)).then(res=>{
                            if(res.code){
                                this.$EClose()
                                if(res.code==1000){
                                    this.$ELoading()
                                    this.$parent.getList().then(()=>{
                                        this.$EClose()
                                    })
                                    this.$message.success('操作成功')
                                    this.show=false
                                    this.resetForm()
                                }else{
                                    this.$message.error(res.message?res.message:'操作失败')
                                }
                            }
                        })
                    }else{
                        return false
                    }
                })
            },
            resetForm(){
                this.$refs['ruleForm'].resetFields()
            },
            // 隐藏表头中的全选框
            cellClass(row) {
                if (row.columnIndex === 0) {
                    return 'disabledCheck'
                }
            },
        }
    };
</script>

<style scoped>
    ::v-deep .el-input__inner{
        max-width: 300px;
    }
    ::v-deep .dialogClass{
        width: auto !important;
        max-width:50vw;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
    }
    ::v-deep .el-dialog__title{
        font-size:15px;
        position: relative;
        padding-left: 15px;
        line-height: 1;
    }
    ::v-deep .el-dialog__title:before{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    ::v-deep .el-dialog__body{
        padding: 30px 40px;
        padding-bottom: 10px;
        max-height: calc(100vh - 25vh - 200px);
        overflow-y: auto;
    }
    .dialog-footer{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .dialog-footer>button:nth-of-type(1){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: rgba(31, 120, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .dialog-footer>button:hover{
        opacity: .9;
        transition: all linear .2s;
    }
    .dialog-footer>button:nth-of-type(2){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
        color: rgba(31, 120, 255, 1);
        font-size: 14px;
    }
    .formNode{
        margin-top: 5px;
        box-sizing: border-box;
    }
    .formNode_title{
        color: rgba(0, 0, 0, 1);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
    }
    .formNode_title:before{
        width: 5px;
        height: 15px;
        border-radius: 4px;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
</style>
