<template>
    <div>
        <el-dialog
                :title="type==='edit'?'查看账户':type==='detail'?'账号详情':'开通保险服务'"
                custom-class="dialogClass"
                :visible.sync="show"
                width="30%">
            <template v-if="type === 'edit'">
                <el-form :hide-required-asterisk="true" :model="form" ref="ruleForm"
                    label-width="auto" class="demo-ruleForm">
                    <div class="formNode">
                        <div class="formNode_title">查看账户</div>
                        <el-form-item label="微保充值账号" prop="insuranceaccount">
                            <el-input v-model="form.insuranceaccount" size="small" :readonly="true"></el-input>
                        </el-form-item>
                    </div>
                    <div class="formNode">
                        <div class="formNode_title">打款公司资料</div>
                        <el-form-item label="企业对公账账户" prop="bankno">
                            <el-input v-model="form.bankno"  size="small" :readonly="true"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            </template>
            <template v-else>
                <el-form :hide-required-asterisk="true"
                :model="form" :rules="rules"  ref="ruleForm"
                label-width="auto" class="demo-ruleForm">
                    <el-form-item required label="公司名称" :prop="type!='detail'?'id':''">
                        <el-cascader
                            :disabled="type === 'detail' || type === 'resubmit'"
                            size="small"
                            ref="ref_address"
                            filterable
                            v-model="form.id"
                            :options="baseList.companyList"
                            :props="{ expandTrigger: 'hover',value:'id',label:'companyname' }"
                            @change="cityChange"
                            placeholder="请选择已开通的公司"
                            clearable></el-cascader>
                        <div v-if="form.id?.length" class="select-company-info">
                            <div>联系人姓名：{{ baseList.selectedCompany?.name || '--'}}</div>
                            <div>联系电话：{{ baseList.selectedCompany?.userphone || '--' }}</div>
                            <div>公司地址：{{ baseList.selectedCompany?.registeraddress || '--' }}</div>
                        </div>
                    </el-form-item>
                    <div class="formNode">
                        <div class="formNode_title">补充公司资料</div>
                        <el-form-item label="企业统一信用代码" :prop="type!='detail'?'taxpayerid':''">
                            <el-input v-model="form.taxpayerid" size="small" placeholder="请输入" :readonly="type=='detail'" aria-placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="开户银行" :prop="type!='detail'?'depositbank':''">
                            <el-input v-model="form.depositbank"  size="small" placeholder="请输入" :readonly="type=='detail'" aria-placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="企业对公账账户" :prop="type!='detail'?'bankno':''">
                            <el-input v-model="form.bankno"  size="small" placeholder="请输入" :readonly="type=='detail'" aria-placeholder="请输入"></el-input>
                        </el-form-item>
                    </div>
                    <div class="formNode">
                        <div class="formNode_title">开通保险类型</div>
                        <el-form-item label="保险类型" prop="insurancetype">
                            <el-checkbox-group v-model="form.insurancetype">
                                <el-checkbox label="PICC" key="PICC">人保意外险</el-checkbox>
                                <el-checkbox label="EMPLOYER_PICC" key="EMPLOYER_PICC">人保雇主险</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer"  v-if="type!=='detail'">
                    <template v-if="type !== 'resubmit'">
                        <el-button @click="() => submitBtn('submit')">提交</el-button>
                        <el-button type="primary" @click="resetForm">重置</el-button>
                    </template>
                    <template v-else>
                        <el-button @click="() => submitBtn('resubmit')">再次提交</el-button>
                        <el-button @click="() => show = false">取消</el-button>
                    </template>
                </span>
            </template>
           
        </el-dialog>
    </div>
</template>

<script>
    import rules from "@/util/js/formRules";
    import {openInsurance, getUserList, reOpenInsurance} from "@/api/workbenche";

    export default {
        props:['type'],
        data() {
            return {
                show: false,
                baseList:{
                    companyList:[],
                    selectedCompany: {}
                },
                form:{
                   id: '',
                   taxpayerid: '',
                   depositbank: '',
                   bankno: '',
                   insurancetype: [],
                   insuranceaccount: ''
                },
                rules:{
                    'id': rules(),
                    "taxpayerid":rules(),
                    "depositbank":rules(),
                    'bankno': rules(),
                    'insurancetype': rules('请选择保险类型'),
                },
            };
        },
        created() {
            console.log('123')
        },
        methods: {
            getCompanyList(){
                getUserList({
                    data: {
                        status: '1',
                        insurancestatus: 0
                    }
                }).then(res=>{
                    console.log('res', res.data)
                    if(res.code){
                        if(res.code==1000){
                        this.baseList.companyList = res.data.list.map(i => i.data1);
                        console.log('this.baseList.companyList', this.baseList.companyList)
                        }else{
                            this.$EClose()
                            this.$message.error('操作失败')
                        }
                    }
                })
            },
            cityChange(value){
                console.log('value--------', value)
                // this.form.id = ;
                this.baseList.selectedCompany = this.baseList.companyList.find(i => i.id === value[0])
            },
            submitBtn(type){
                this.$refs['ruleForm'].validate(valid=>{
                    if(valid){
                        this.$ELoading()
                        const req = {
                           ...this.form,
                           id: this.form.id[0],
                           insurancetype: this.form.insurancetype.join('|')
                        }
                        const api = type === 'resubmit' ? reOpenInsurance : openInsurance;
                        api(this.$req(req)).then(res=>{
                            if(res.code){
                                if(res.code==1000){
                                    if(type === 'resubmit'){
                                        this.$parent.getList().then(()=>{
                                            this.$EClose()
                                        })
                                    }else{
                                        this.$parent.addGetList()
                                    }

                                    this.$message.success('操作成功')
                                    this.show=false
                                    this.resetForm()
                                }else{
                                    this.$message.error(res.message?res.message:'操作失败')
                                    this.$EClose()
                                }
                            }
                        })
                    }else{
                        return false
                    }
                })
            },
            resetForm(){
                this.$refs['ruleForm'].resetFields();
                this.form.id = '';
                this.form.insurancetype = [];
            },
        }
    };
</script>

<style scoped>
    ::v-deep .el-checkbox__inner {
        border-radius: 50%;
    }
    ::v-deep .el-input__inner{
        max-width: 300px;
    }
    ::v-deep .dialogClass{
        width: auto !important;
        max-width:50vw;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
    }
    ::v-deep .el-dialog__title{
        font-size:15px;
        position: relative;
        padding-left: 15px;
        line-height: 1;
    }
    ::v-deep .el-dialog__title:before{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    ::v-deep .el-dialog__body{
        padding: 30px 40px;
        padding-bottom: 10px;
        max-height: calc(100vh - 25vh - 200px);
        overflow-y: auto;
    }
    .dialog-footer{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .dialog-footer>button:nth-of-type(1){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: rgba(31, 120, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .dialog-footer>button:hover{
        opacity: .9;
        transition: all linear .2s;
    }
    .dialog-footer>button:nth-of-type(2){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
        color: rgba(31, 120, 255, 1);
        font-size: 14px;
    }
    .formNode{
        margin-top: 5px;
        box-sizing: border-box;
    }
    .formNode_title{
        color: rgba(0, 0, 0, 1);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
    }
    .formNode_title:before{
        width: 5px;
        height: 15px;
        border-radius: 4px;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .select-company-info {
        border-radius: 2px;
        background: #EDF6FF;
        padding-left: 15px;
        padding-right: 15px;
    }
</style>
