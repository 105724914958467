import request from "@/util/require";
//获取所有权限
export function getAllAuthority() {
    return   request({
        url:'/paasbase/system/role/getSystemMenu',method:'get'
    })
}
//角色列表-表格数据
export function getRoleTable(data) {
    return   request({
        url:'/paasbase/system/role/getRoleAndMenu',method:'post',data
    })
}
//角色列表-添加角色
export function  addRole(data) {
    return request({
        url:'/paasbase/system/role/addRole',method:'post',data
    })
}
//角色列表-删除角色
export function  delRole(roleId) {
    return request({
        url:'/paasbase/system/role/deleteRole',method:'delete',params:{roleId}
    })
}
//账号管理-添加账号
export function addUser(data) {
    return   request({
        url:'/paasbase/system/role/newUser',method:'post',data
    })
}
//账号管理-修改账号
export function alterUser(data) {
    return   request({
        url:'/paasbase/system/rolesystemMenu/modifyUserPermission',method:'post',data,
    })
}
//账号管理-获取表格数据
export function getUserTable(data) {
    return   request({
        url:'/paasbase/system/role/getUserAndMenu',method:'post',data
    })
}
//账号管理-用户完善信息
export function userInfoOver(data) {
    return   request({
        url:'/paasbase/system/role/modifyUser',method:'post',data
    })
}
//账号管理-账号注销
export function userDelete(id) {
    return   request({
        url:'/paasbase/system/role/deleteUser',method:'post',data:{id}
    })
}
//账号管理-账号状态控制
export function disableEnable(id,state) {
    return   request({
        url:'/paasbase/system/role/disabledUser',method:'post',data:{id,state}
    })
}
//根据用户id获取权限集合
export function bindRoleAuthority() {
    return   request({
        url:'/paasbase/system/role/saveUserRole',method:'get'
    })
}
//根据角色id集合获取权限集合
export function roleIdsGetList() {
    return   request({
        url:'/paasbase/system/role/getRoleAndMenuList',method:'get'
    })
}
//根据用户id获取权限集合
export function userIdGetList() {
    return   request({
        url:'/paasbase/system/role/saveUserMenu',method:'get'
    })
}
