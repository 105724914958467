<template>
    <div class="vip">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "vip"
    }
</script>

<style scoped>
.vip{
    width: 100%;
    height: 100%;
}
</style>
