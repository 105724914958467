<template>
    <div class="pageMain">
        <div class="tableSearch">
            <div class="fromHeader">
                <div>账号列表</div>
                <!--     头   添加弹出窗-->
                <div class="popup">
                    <el-dialog title="添加账号" :visible.sync="dialogFormVisible">
                        <div class="scroll" ref="scroll">
                            <el-form :model="addFormBody.systemUser"  ref="refFrom" :rules="ruleForm">
                                <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                                    <div style="width: 70%">
                                        <el-input v-model="addFormBody.systemUser.username" autocomplete="off" maxlength="11" @change="setAcont" placeholder="请输入英文或数字(最长11位)"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="密码"  :label-width="formLabelWidth" prop="password" >
                                    <div style="width: 70%">
                                        <el-input v-model="addFormBody.systemUser.password" autocomplete="off"  placeholder="请输入密码" show-password @change="setPass"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="设置角色" :label-width="formLabelWidth" prop="roleids">
                                    <div style="max-width:40%" class="multipleSelect">
                                        <el-select v-model="addFormBody.systemUser.roleids" multiple placeholder="请选择" @change="setRole">
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-form-item>
                                <el-form-item label="权限" :label-width="formLabelWidth">
                                    <div class="tree">
                                        <el-tree
                                                :data="treeData"
                                                show-checkbox
                                                node-key="id"
                                                ref="tree"
                                                :props="defaultProps">
                                        </el-tree>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="getCheckedKeys">添加</el-button>
                            <el-button @click="resetForm">重置</el-button>
                        </div>
                    </el-dialog>
                </div>
                <!--     尾   添加弹出窗-->
                <!--     头   修改弹出窗-->
                <div class="popup">
                    <el-dialog title="修改账号" :visible.sync="dialogFormVisible2">
                        <div class="scroll" ref="scroll">
                            <el-form :model="alterForm" :rules="alterRuleForm" :inline-message="true" ref="alterForm">
                                <el-form-item label="账号" :label-width="formLabelWidth" >
                                    <div style="width: 70%">
                                        <el-input v-model="alterForm.systemUser.username" autocomplete="off" placeholder="请输入账号" readonly disabled></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="设置角色" :label-width="formLabelWidth" prop="systemUser.roleids">
                                    <div style="max-width:60%" class="multipleSelect">
                                        <el-select v-model="alterForm.systemUser.roleids" multiple placeholder="请选择"  @change="setRole2">
                                            <el-option
                                                    v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-form-item>
                                <el-form-item label="权限" :label-width="formLabelWidth">
                                    <div class="tree">
                                        <el-tree
                                                :data="treeData"
                                                show-checkbox
                                                node-key="id"
                                                ref="tree2"
                                                :default-expanded-keys="defList"
                                                :props="defaultProps">
                                        </el-tree>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="getCheckedKeys2">保存</el-button>
                        </div>
                    </el-dialog>
                </div>
                <!--     尾   修改弹出窗-->
                <!--     头   账户信息弹出窗-->
                <div class="popup">
                    <el-dialog title="账户信息" :visible.sync="dialogFormVisible3">
                        <div class="scroll" ref="scroll">
                            <el-form v-if="infoForm" :model="infoForm" :rules="infoRuleForm" inline-message ref="infoFormRef">
                                    <div style="text-align: center;margin-bottom: 10px;">
                                        <img :src="infoForm.imagehead" alt="" width="100px" height="100px" style="border-radius: 50%;background: #c0c4cc">
                                    </div>
                                <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <div style="width: 70%">
                                        <el-input v-model="infoForm.name" autocomplete="off" maxlength="5" placeholder="请输入姓名(最长5位)"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="手机号"  :label-width="formLabelWidth"  prop="phone">
                                    <div style="width: 70%">
                                        <el-input v-model.number.trim="infoForm.phone"   maxlength="11" placeholder="请输入手机号(11位)"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="性别"  :label-width="formLabelWidth">
                                    <div style="width: 70%" class="sexRadio">
                                        <el-radio v-model="infoForm.sex" label="1">男</el-radio>
                                        <el-radio v-model="infoForm.sex" label="2">女</el-radio>
                                        <el-radio v-model="infoForm.sex" label="0">未知</el-radio>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="userInfoOver">保存</el-button>
                        </div>
                    </el-dialog>
                </div>
                <!--     尾   账户信息弹出窗-->
                <div class="newAddBtn"  @click="openDialog">
                    <button>
                        <img src="../../../assets/account/bi.png" alt=""><div>添加账号</div>
                    </button>
                </div>
            </div>
            <!--头部查询功能-->
            <div class="fromLabels">
                <div class="labelDiv">
                    <div class="label_name diquxuanze">状态</div>
                    <template>
                        <el-select v-model="tableReq.state" placeholder="状态" @change="changeSearchState">
                            <el-option
                                    v-for="item in stateArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </div>
                <div class="labelDiv">
                    <div class="label_name diquxuanze">性别</div>
                    <template>
                        <el-select v-model="tableReq.sex" placeholder="性别" @change="changeSearchSex">
                            <el-option
                                    v-for="item in sexArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </div>
                <div class="labelDiv">
                    <div class="label_name diquxuanze">身份角色</div>
                    <template>
                        <el-select v-model="tableReq.roleId" placeholder="身份角色" @change="changeSearchRole">
                            <el-option
                                    v-for="item in remarkArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </div>
                <div class="labelDiv">
                    <div class="label_name diquxuanze">创建时间</div>
                    <template>
                        <el-date-picker
                                prefix-icon=" "
                                v-model="searchTime"
                                @change="searchTimeChange"
                                type="daterange"
                                value-format="timestamp"
                                range-separator=""
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </template>
                </div>
                <div class="btnLabel_Li">
                    <button @click="getUserTable">
                        <img src="../../../assets/button/搜索icon.png" alt=""><div style="margin-left: 8px">搜索</div>
                    </button>
                    <button style="margin-left: 10px" @click="searchReset">
                        <img src="../../../assets/button/重置icon.png" alt=""><div style="margin-left: 8px">重置</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="tableMain">
            <div class="tableBox">
                <div class="scroll">
                    <template>
                        <el-table
                                :data="tableData.data"
                                style="width: 100%"
                                :header-cell-style="{ background: '#F5F6FA', textAlign: 'center' }"
                                :cell-style="{ textAlign: 'center' }"
                                height="100%"
                                element-loading-text="拼命加载中">
                            <el-table-column  label="序号" width="80" sortable >
                                <template slot-scope="scope">
                                    {{scope.$index+1+(tableReq.pageSize)*(tableReq.pageNo-1)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="city" label="账号信息" width="160">
                                <template slot-scope="scope">
                                    <div>账号：{{scope.row.systemUser.username}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="姓名" min-width="80">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.name?scope.row.systemUser.name:'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="性别" width="50">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.sex?scope.row.systemUser.sex==1?'男':scope.row.systemUser.sex==2?'女':'未知':'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="手机号" width="120">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.phone?scope.row.systemUser.phone:'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="地区">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.adcode?$iApi.dictPropArea(scope.row.systemUser.adcode).toString().replaceAll(',','/'):'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="身份角色" min-width="90">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.remark?scope.row.systemUser.remark:'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="status" label="创建时间" width="160" sortable>
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.createtime?$iApi.moment(scope.row.systemUser.createtime,'YYYY-MM-DD HH:mm:ss'):'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="provence" label="状态">
                                <template slot-scope="scope">
                                    <div>{{scope.row.systemUser.state==1?'启用':scope.row.systemUser.state==2?'禁用':'空'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column  label="操作" width="190">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="openDialog3(scope.row)">详情</el-button>
                                    <el-button type="text" size="small" @click="openDialog2(scope.row)">修改</el-button>
                                    <el-button type="text" size="small" @click="deleteUser(scope.row)">注销</el-button>
                                    <el-button type="text" size="small" v-if="scope.row.systemUser.state==2" @click="edFlag?disableEnable(scope.row,1):''">启用</el-button>
                                    <el-button type="text" size="small" v-if="scope.row.systemUser.state==1" @click="edFlag?disableEnable(scope.row,2):''">禁用</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
                <!--          分页标签-->
                <div class="fenye">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableReq.pageNo"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableReq.limit"
                            layout="total, prev, pager, next, sizes"
                            :total="tableData.count">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import  {addUser,getRoleTable,getUserTable,alterUser,userInfoOver,userDelete,disableEnable} from  '../../../api/authorityManage'
    import  md5 from  '../../../util/js/md5'
    export default {
        name: "platformAdminDefault",
        watch:{

        },
        data() {
            return {
                tableData: [],//表格数据
                searchTime:'',
                tableReq:{
                    roleId:null,
                    state:null,
                    sex:null,
                    phone:null,
                    adcode:null,
                    startTime:null,
                    endTime:null,
                    pageNo:1,
                    pageSize:20,
                },
                searchBody:{
                    searFlag:false
                },
                //发布弹窗对象
                dialogFormVisible: false,//添加弹窗控制
                dialogFormVisible2: false,//修改弹窗控制
                dialogFormVisible3:false,//账户信息弹窗控制
                reset:false,//重置
                formLabelWidth: '120px',//
                limitCount:0,
                options:[],//角色设置项数据
                defaultProps: {
                    children: 'detail',
                    label: 'name'
                },
                defList:[],//默认树选中
                treeData:[],//树数据
                addForm:{
                    systemUser:{
                        username:'',
                        password:'',
                        password2:'',
                        roleids:'',
                        remark:'',
                    },
                    list:[]
                },
                addFormBody:{
                    systemUser:{
                        username:'',
                        password:'',
                        password2:'',
                        roleids:[],
                        remark:'',
                    },
                    list:[]
                },
                ruleForm: {
                    username:[
                        { required: true, message: '不能为空', trigger: 'blur' },
                        {pattern:/^[a-zA-Z0-9]{1,11}$/,message: '只能英文或数字',trigger: 'blur'}
                        ],
                    password:[{ required: true, message: '不能为空', trigger: 'blur' }],
                    roleids:[{ required: true, message: '不能为空', trigger: 'blur' }],
                },
                alterForm:{
                    systemUser:{
                        username:'',
                        password:'',
                        roleids:[],
                        id:''
                    },
                    list:[]
                },
                alterRuleForm: {
                    "systemUser.username":[{ required: true, message: '不能为空', trigger: 'blur' }],
                    "systemUser.password":[{ required: true, message: '不能为空', trigger: 'blur' }],
                    "systemUser.roleids":[{ required: true, message: '不能为空', trigger: 'blur' }],
                },
                alterReq:{
                    systemUser:{
                        id:'',
                        roleids:'',
                    },
                    list:[]
                },
                infoForm:{
                    name:'',phone:'',sex:'',id:'',imagehead:''
                },//信息回显表单
                infoRuleForm:{
                    phone:[{pattern:/^[0-9][0-9]*$/,message:'格式无效',trigger:'change'}]
                },
                overInfoReq:{
                    id:'',
                    name:'',
                    phone:'',
                    sex:'',
                },
                overInfoShow:{
                    name:'',
                    phone:'',
                    sex:'',
                },
                roleids:[],//设置角色id集合
                roleids2:[],//修改设置角色id集合
                roleArr:[],//角色列表，
                edFlag:true,//启用禁用控制
                stateArr:[
                    {label:'全部',value:null},
                    {label:'启用',value:1},
                    {label:'禁用',value:2}
                ],
                sexArr:[
                    {label:'全部',value:null},
                    {label:'男',value:1},
                    {label:'女',value:2}
                ],
                remarkArr:[],
            };
        },
        created(){
            this.treeData=JSON.parse(localStorage.getItem('paas_routerMenu')).detail
            this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
            this.getUserTable().then(()=>{
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.getRoleTable().then(()=>{
                    this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                   this.$loading().close()
                })
            })
        },
        //////
        methods:{
            changeSearchState(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
            },
            changeSearchSex(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
            },
            changeSearchRole(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
            },
            //搜索时间值转换
            searchTimeChange(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
                if(v){
                    let arr = this.$iApi.timeValArrHandle(v)
                    this.tableReq.startTime=arr[0]
                    this.tableReq.endTime=arr[1]
                }else{
                    this.tableReq.startTime=null
                    this.tableReq.endTime=null
                }
            },
            //获取账户表格
            getUserTable(){
                 this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                if(this.searchBody.searFlag){
                    this.tableReq.pageNo=1
                    this.searchBody.searFlag=false
                }
                return new Promise(resolve => {
                    getUserTable(this.tableReq).then(res=>{
                        this.$loading().close()
                        if(res.code==1000){
                            this.tableData=res.data
                        }
                        resolve()
                    }).catch(()=>{
                        this.loading=false
                    })
                })
            },
            //获取角色及相应权限树
            getRoleTable(){
               return new Promise(resolve => {
                   getRoleTable({name:'',pageNo:1,pageSize:20}).then(res=>{
                       if(res.code==1000){
                           this.roleArr=res.data.data
                           this.options=this.roleArr.map(item=>{
                               return {value: item.systemRole.id,label: item.systemRole.name}
                           })
                           this.remarkArr=[...this.options]
                           this.remarkArr.unshift({value: null,label: '不限'})
                       }
                       resolve()
                   })
               })
            },
            //添加
            openDialog(){
                this.dialogFormVisible = true
                this.$nextTick(()=>{
                    this.$refs['refFrom'].clearValidate()
                })
            },
            //修改
            openDialog2(item){
                this.dialogFormVisible2 = true
                let {username,password,id}=item.systemUser
                this.alterForm.systemUser.username=username
                this.alterForm.systemUser.password=password
                this.alterForm.systemUser.id=id
                this.alterForm.list=item.menuList?item.menuList.map(item=>item.id).sort(function(a,b){return a-b}):[]
                this.alterReq.systemUser.roleids=item.systemUser.roleids
                let roleid = (item.systemUser.roleids).substring(1,(item.systemUser.roleids).length-1)||''
                if(roleid){
                    this.alterForm.systemUser.roleids=roleid.split(',').map(item=>Number(item))
                }else{
                    this.alterForm.systemUser.roleids=roleid?[Number(roleid)]:''
                }
                if(!item.menuList){
                    return
                }
                let tempArr = item.menuList.map(item=>item.id)
                let defList = []
                this.treeData.forEach(item=>{
                    tempArr.forEach(dItem=>{
                        if(dItem==item.id&&!item.detail){
                            defList.push(dItem)
                        }
                    })
                    if(item.detail){
                        item.detail.forEach(vItem=>{
                            tempArr.forEach(dItem=>{
                                if(dItem==vItem.id&&!vItem.detail){
                                    defList.push(dItem)
                                }
                            })
                            if(vItem.detail){
                                vItem.detail.forEach(wItem=>{
                                    tempArr.forEach(dItem=>{
                                        if(dItem==wItem.id&&!wItem.detail){
                                            defList.push(dItem)
                                        }
                                    })
                                })
                            }
                        })
                    }
                })
                this.$nextTick(()=>{
                    this.$refs.tree2.setCheckedKeys([])
                    this.$refs.tree2.setCheckedKeys(defList)
                })

            },
            //账号信息
            openDialog3(item){
                this.dialogFormVisible3 = true
                let {name,phone,sex,id,imagehead} = item.systemUser
                this.infoForm={name,phone,sex,id,imagehead}
            },
            handleSizeChange(val){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.searchBody.searFlag=true
                this.tableReq.pageSize=val
                this.getUserTable()
            },
            handleCurrentChange(val){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.tableReq.pageNo=val
                this.getUserTable()
            },
            setAcont(v){
                this.addForm.systemUser.username=v
            },
            setPass(v){
                this.addForm.systemUser.password=v
                this.addForm.systemUser.password2=v
            },
            //添加账号-设置角色
            setRole(v){
                if(!v.length){
                    this.addForm.systemUser.roleids=''
                    this.addForm.systemUser.remark=''
                    this.$refs.tree.setCheckedKeys([])
                    return
                }
                this.addForm.systemUser.remark=''
                this.addForm.systemUser.roleids=','+String(v)+','
                v.forEach(item=>{
                    this.options.forEach(roleItem=>{
                        if(roleItem.value==item){
                            this.addForm.systemUser.remark+=roleItem.label+','
                        }
                    })
                })
                if(this.addForm.systemUser.remark){
                    this.addForm.systemUser.remark=this.addForm.systemUser.remark.substring(0,this.addForm.systemUser.remark.length-1)
                }
                this.$refs.tree.setCheckedKeys([])
                let defList = []
                this.roleArr.forEach(item=>{
                    v.forEach(vid=>{
                        if(item.systemRole.id==vid){
                            if(!item.list) return
                            if(item.list){
                                let tempArr = item.list.map(item=>item.id)
                                this.treeData.forEach(item=>{
                                    tempArr.forEach(dItem=>{
                                        if(dItem==item.id&&!item.detail){
                                            defList.push(dItem)
                                        }
                                    })
                                    if(item.detail){
                                        item.detail.forEach(vItem=>{
                                            tempArr.forEach(dItem=>{
                                                if(dItem==vItem.id&&!vItem.detail){
                                                    defList.push(dItem)
                                                }
                                            })
                                            if(vItem.detail){
                                                vItem.detail.forEach(wItem=>{
                                                    tempArr.forEach(dItem=>{
                                                        if(dItem==wItem.id&&!wItem.detail){
                                                            defList.push(dItem)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                })
                this.$refs.tree.setCheckedKeys(defList)//
            },
            //修改账号-设置角色
            setRole2(v){
                this.$refs.tree2.setCheckedKeys([])
                if(!v.length){
                    this.alterForm.systemUser.roleids=[]
                    return
                }
                this.alterForm.systemUser.remark=''
                this.alterReq.systemUser.roleids=','+String(v)+','
                v.forEach(item=>{
                    this.options.forEach(roleItem=>{
                        if(roleItem.value==item){
                            this.alterForm.systemUser.remark+=roleItem.label+','
                        }
                    })
                })
                if(this.alterForm.systemUser.remark){
                    this.alterForm.systemUser.remark=this.alterForm.systemUser.remark.substring(0,this.alterForm.systemUser.remark.length-1)
                }
                let defList = []
                this.roleArr.forEach(item=>{
                    v.forEach(vid=>{
                        if(item.systemRole.id==vid){
                            if(!item.list) return
                            if(item.list){
                                let tempArr = item.list.map(item=>item.id)
                                this.treeData.forEach(item=>{
                                    tempArr.forEach(dItem=>{
                                        if(dItem==item.id&&!item.detail){
                                            defList.push(dItem)
                                        }
                                    })
                                    if(item.detail){
                                        item.detail.forEach(vItem=>{
                                            tempArr.forEach(dItem=>{
                                                if(dItem==vItem.id&&!vItem.detail){
                                                    defList.push(dItem)
                                                }
                                            })
                                            if(vItem.detail){
                                                vItem.detail.forEach(wItem=>{
                                                    tempArr.forEach(dItem=>{
                                                        if(dItem==wItem.id&&!wItem.detail){
                                                            defList.push(dItem)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                })
                console.log(defList)
                this.$refs.tree2.setCheckedKeys(defList)//
                this.alterForm.list=this.$refs.tree2.getCheckedNodes(false,true).map(item=>item.id)
            },
            //添加账号
            getCheckedKeys(){
                this.$refs['refFrom'].validate((valid)=>{
                    if(valid){
                         this.$loading({
                            lock: true,
                            text: '正在添加',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.1)'
                        });

                        this.addForm.list=this.$refs.tree.getCheckedNodes(false,true).map(item=>item.id)
                        this.addForm.systemUser.password=md5.hexMD5(this.addForm.systemUser.password)
                        addUser(this.addForm).then(res=>{
                            this.$loading().close();
                            if(res.code==1000){
                                this.$message.success('添加成功')
                                this.getUserTable()
                                this.dialogFormVisible = false
                                this.resetForm()
                            }else{
                                this.$message.error('添加失败')
                            }
                        }).catch(()=>{
                            this.$loading().close();
                        })
                    } else {
                        return false;
                    }
                })

            },
            //修改账号
            getCheckedKeys2(){
                this.alterForm.list=this.$refs.tree2.getCheckedNodes(false,true).map(item=>item.id)
                this.$refs['alterForm'].validate((valid)=>{
                    if(valid){
                         this.$loading({
                            lock: true,
                            text: '正在保存',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.1)'
                        });
                        let {id}=this.alterForm.systemUser
                        this.alterReq.systemUser.id=id
                        this.alterReq.list=this.alterForm.list
                        let remark = this.alterReq.systemUser.roleids.substring(1,this.alterReq.systemUser.roleids.length-1)
                        if(remark.includes(',')){
                            remark=remark.split(',')
                        }else{
                            remark=[remark]
                        }
                        let temp = []
                        this.options.forEach(item=>{
                            remark.forEach(li=>{
                                if(item.value==li){
                                    temp.push(item.label)
                                }
                            })
                        })
                        this.alterReq.systemUser.remark=String(temp)
                        alterUser(this.alterReq).then(res=>{
                            this.$loading().close();
                            if(res.code==1000){
                                this.$message.success('修改成功')
                                this.getUserTable()
                                this.dialogFormVisible2 = false
                            }else{
                                this.$message.error('修改失败')
                            }
                        }).catch(()=>{
                            this.$loading().close();
                        })
                    } else {
                        return false;
                    }
                })
            },
            //信息完善
            userInfoOver(){
                this.$refs['infoFormRef'].validate(v=>{
                    if(v){
                        this.$loading({
                            lock: true,
                            text: '正在保存',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.1)'
                        });
                        let {name,phone,sex,id} = this.infoForm
                        this.overInfoReq={ name,phone,sex,id}
                        userInfoOver(this.overInfoReq).then(res=>{
                            this.$loading().close()
                            if(res.code==1000){
                                this.getUserTable()
                                this.dialogFormVisible3 = false
                                this.$message.success('保存成功')
                            }else{
                                this.$message.error('保存失败')
                            }
                        }).catch(()=>{
                            this.$loading().close()
                        })
                    }else{
                        return false
                    }
                })

            },
            //账号注销
            deleteUser(item){
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    userDelete(item.systemUser.id).then(res=>{
                        if(res.code==1000){
                            this.getUserTable()
                            this.$message.success('注销成功')
                        }else{
                            this.$message.error('注销失败')
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })

            },
            //启用禁用
            disableEnable(item,state){
                this.edFlag=false
                disableEnable(item.systemUser.id,state).then(res=>{
                    if(res.code==1000){
                        this.getUserTable().then(
                            this.edFlag=true
                        )
                        this.$message.success('操作成功')
                    }else{
                        this.$message.error('操作失败')
                    }
                })
            },
            //重置表单
            resetForm(){
                this.roleids=[]
                this.addForm={
                    systemUser:{
                        username:'',
                            password:'',
                            roleids:'',
                            remark:''
                    },
                    list:[]
                }
                this.addFormBody={
                    systemUser:{
                        username:'',
                        password:'',
                        roleids:'',
                    },
                    list:[]
                }
               this.$refs.tree.setCheckedKeys([])
               this.$refs['refFrom'].clearValidate()
            },
            searchReset(){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.searchBody.searFlag=true
                this.tableReq={
                        roleId:null,
                        state:null,
                        sex:null,
                        phone:null,
                        adcode:null,
                        startTime:null,
                        endTime:null,
                        pageNo:1,
                        pageSize:20,
                }
                this.searchTime=''
                this.getUserTable()
            },
        },
        computed:{

        },
        watch:{

        },
    };
</script>

<style scoped>
    .pageMain {
        width: 100%;
        height: 100%;
    }
    .tableSearch {
        width: 100%;
        height: 170px;
        padding: 35px 54px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .fromLabels {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .el-select {
        width: 240px;
        height: 31px;
        opacity: 1;
        box-sizing: border-box;
        width: 100%;
    }
    ::v-deep  .fromLabels .el-input__inner {
        height: 31px !important;
        /*line-height: 31px;*/
    }
    ::v-deep  .el-select .el-input .el-select__caret {
        line-height: 31px;
    }
    .el-input__inner {
        border-radius: 0;
        background: #f4f4f5;
    }

    ::v-deep  .el-select .el-input .el-select__caret {
        color: rgba(100, 102, 107, 1);
    }
    .labelDiv {
        margin-right: 12px;
        position: relative;
    }
    .btnLabel_Li {
        margin-left: 48px;
    }
    .btnLabel_Li > img:hover {
        cursor: pointer;
    }
    .btnLabel_Li {
        width: 193px;
        height: 35px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 36px;
    }
    .newAddBtn {
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 28px;
    }
    .fromHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /*______________________________________*/
    .tableMain {
        width: 100%;
        height: calc(100% - 170px);
        background: #f5f6fa;
        padding: 0px 20px;
        box-sizing: border-box;
    }
    .tableBox {
        width: 100%;
        height: calc(100%);
        background: white;
        position: relative;
    }
    .tableBox>.scroll{
        width: 100%;
        height: calc(100% - 52px);
        overflow: auto;
    }
    ::v-deep .el-table tr {
        background: transparent;
    }
    ::v-deep .el-table th.el-table__cell {
        background: transparent;
    }
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
    }
    ::v-deep  .el-table .sort-caret.ascending {
        border-bottom-color: #409eff;
        top: 5px;
    }
    ::v-deep  .el-table .sort-caret.descending {
        border-top-color: #409eff;
        bottom: 7px;
    }
    ::v-deep  .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #c0c4cc;
    }
    ::v-deep  .el-table .descending .sort-caret.descending {
        border-top-color: #c0c4cc;
    }
    .label_name {
        position: absolute;
        top: 7px;
        left: 16px;
        z-index: 2;
        color: rgba(138, 139, 145, 1);
        font-size: 12px;
    }

    .labelDiv:nth-of-type(1) .el-cascader{
        line-height: 30px;
    }
    ::v-deep  .labelDiv:nth-of-type(1) .el-input__icon{
        line-height: 33px;
        color: rgba(100, 102, 107, 1);
    }
    ::v-deep .fromLabels .el-input__inner {
        width: 100%;
        /*padding-left: 75px !important;*/
        box-sizing: border-box;
        font-size: 12px;
        text-align: center;
    }
    ::v-deep .fromLabels  .labelDiv:nth-of-type(3) .el-input__inner {
        padding-left: 50px !important;
    }
    ::v-deep .fromLabels  .labelDiv:nth-of-type(4) .el-input__inner {
        width: 100%;
        padding-left: 75px !important;
        box-sizing: border-box;
        font-size: 12px;
    }
    .el-select-dropdown__item {
        text-align: center;
        /*padding-left: 50px;*/
    }
    /*发布属性*/
    ::v-deep  .popup .el-dialog{
        width: 30%;
        min-width:600px;
        border-radius: 10px;
        /*margin-top: 10vh !important;*/
     }
    ::v-deep  .popup .el-dialog__title{
        width: 64px;
        height: 24px;
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        margin-left: 40px;
        left: -8065px;
        top: -3589px;
    }
    ::v-deep  .popup .el-dialog__title:before{
        content:'';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background:rgba(31, 120, 255, 1)  ;
        position: absolute;
        top: 27px;
        left: 40px;
    }
    ::v-deep  .popup .el-dialog__body{
        padding: 10px 20px;
    }

    ::v-deep  .popup .el-form-item__label{
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        vertical-align: top;
    }
    ::v-deep  .popup .el-form-item{
        margin-bottom: 15px;
    }
    ::v-deep  .popup .el-col-1{
        margin: 0 5px;
        text-align: center;
        color: black;
    }
    ::v-deep  .popup .el-col-2{
        width: 1px;
    }
    ::v-deep  .popup .el-col-11{
        width: 130px;
    }
    ::v-deep  .popup .el-input__prefix{
        display: none;
    }
    ::v-deep  .popup .el-input--suffix .el-input__inner{
        /*height: 31px !important;*/
    }
    ::v-deep  .popup .el-input--suffix .el-input__inner{
        padding: 0 15px;
    }

    ::v-deep  .popup .el-dialog__footer{
        width: 100%;
        position: relative;
        bottom: 10px;
    }

    ::v-deep  .popup  .dialog-footer .el-button:nth-of-type(1){
        background-size: cover;
        border-radius: 5px;
        color: white;
        width: 265px;
        height: 61px;
        opacity: 1;
        background: rgba(31, 120, 255, 1);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    ::v-deep  .popup .dialog-footer .el-button:nth-of-type(2){
        color: rgba(31, 120, 255, 1);
        border-radius: 5px;
        margin-left: 38px;
        width: 205px;
        height: 61px;
        opacity: 1;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    ::v-deep  .popup .scroll{
        max-height: 60vh;
        overflow-y: auto;
        padding-bottom: 10px;
        box-sizing: border-box;
        position: relative;
    }
    ::v-deep  .popup .scroll .el-input__inner{
        height: 31px;
        line-height: 31px;
    }
    .dialog-footer{
        text-align: center;
    }
    ::v-deep  .popper__arrow::after{
        display: none;
    }
    ::v-deep  .popper__arrow{
        display: none !important;
    }
    ::v-deep  .popup .el-popper{
        margin-top: 0;
    }

    /*  按钮*/
    .btnLabel_Li>button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btnLabel_Li img{
        width: 13px;
    }
    .btnLabel_Li>button:nth-of-type(1){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid  rgba(73, 119, 252, 1);
    }
    .btnLabel_Li>button:nth-of-type(2){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 0.5px solid rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(73, 119, 252, 1);
    }
    /*发布职位*/
    .newAddBtn>button{
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid rgba(73, 119, 252, 1) ;
    }
    .newAddBtn img{
        width: 15px;
        height: 13.83px;
        opacity: 1;
        margin-right: 7px;
    }

    .fenye{
        position: absolute;
        bottom: 10px;
        right:10px;
        height: 32px;
        text-align: right;
    }
    /*分页bug解决*/
    ::v-deep  .fenye .el-input__inner{
        padding: 0 15px !important;
    }
    ::v-deep  .scroll .el-table .el-table__cell{
        padding: 4px 0 !important;
    }
    .btnLabel_Li>button:hover{
        cursor: pointer;
    }
    .newAddBtn>button:hover{
        cursor: pointer;
    }
    ::v-deep   .el-table .el-table__cell.gutter{
        background:#F5F6FA;
    }
</style>
<!--搜搜区域 名称控件-->
<style scoped>
    ::v-deep   .labelDiv  .el-input__suffix {
        display: flex;
        align-items: center;
    }
    .el-radio {
        color: #606266;
        cursor: pointer;
        visibility: hidden;
        margin-right: 30px;
    }
</style>
<!--搜索区域 时间控件-->
<style scoped>
    .labelDiv:nth-of-type(4){
        /*width:25vw;*/
        max-width: 415px;
    }
    .labelDiv:nth-of-type(4) .el-input__inner{
        background: white;
    }
    .labelDiv:nth-of-type(4) .el-range-editor.el-input__inner{
        border-radius:4px;
    }
    ::v-deep  .labelDiv .el-date-editor .el-range__close-icon{
        line-height: 23px;
    }
    ::v-deep  .labelDiv .el-date-editor .el-range-input{
        width: 100%;
    }
</style>
<!--设置角色-->
<style scoped>
    ::v-deep  .multipleSelect .el-select{
        height:  auto !important;
    }
    .tree{
        padding-right: 120px;
    }
</style>
<style scoped>
   ::v-deep  .sexRadio .el-radio{
       visibility: inherit;
    }
</style>
