<template>
    <div class="home">
        <div class="header">
            <el-row style="height: 100%">
                <el-col :span="16">
                        <el-row :gutter="20">
                            <div class="colClass">
                                <div class="rowTitle">核心数据</div>
                                <div class="imgText1">
                                    <el-row>
                                        <el-col :span="6"  v-for="(item,i) in coreList" :key="i">
                                            <div class="imgText1Li">
                                                <img :src="require(`../../../assets/home/${item.img}.png`)" alt="">
                                                <span>{{item.name}}</span>
                                            </div>
                                            <div class="imgText1Num">
                                                <span>{{item.num}}</span>
                                                <span>{{item.uni}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-row>
                        <el-row :gutter="20">
                            <div class="colClass">
                                <div class="rowTitle">快速入口</div>
                                <div class="imgText2">
                                    <el-row>
                                        <el-col :span="5" v-for="(item,i) in inletList" :key="i">
                                            <div class="imgText2Li" @click="$router.push(item.path)">
                                                <img :src="require(`../../../assets/home/${item.img}.png`)" alt="">
                                                <div>{{item.name}}</div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-row>
                </el-col>
                <el-col :span="8" style="height: 100%">
                    <div class="colClass" style="margin-left: 20px;height: 100%">
                        <div>
                            <div class="rowTitle">企业数据</div>
                            <el-row>
                                <el-col :span="12">
                                    <div class="textList">
                                        <div class="textLi">
                                            <span>平台人才库（人）</span>
                                        </div>
                                        <div class="textLiNum">
                                            <span>20</span>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="textList">
                                        <div class="textLi">
                                            <span>本月发放金额（元）</span>
                                        </div>
                                        <div class="textLiNum">
                                            <span>2</span>
                                        </div>
                                    </div>
                                </el-col>
<!--                                <el-col :span="8">-->
<!--                                    <div class="textList">-->
<!--                                        <div class="textLi">-->
<!--                                            <span>累计发放金额</span>-->
<!--                                        </div>-->
<!--                                        <div class="textLiNum">-->
<!--                                            <span>2</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </el-col>-->
                            </el-row>
                        </div>
                        <div style="margin-top: 85px">
                            <div class="textList">
                                <div class="textLi">
                                    <span>累计发放金额（元）</span>
                                </div>
                                <div class="textLiNum">
                                    <span>2</span>
                                </div>
                            </div>
<!--                            <div class="rowTitle">账户余额</div>-->
<!--                            <div class="balance">-->
<!--                                <img src="../../../assets/home/balance.png" width="48px" height="48px" alt="">-->
<!--                                <div class="balanceInfo">-->
<!--                                    <div class="balanceLogo">-->
<!--                                        <span>主账户余额</span>-->
<!--                                        <img src="../../../assets/home/doubt.png" width="16px" height="16px" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="balanceNum">-->
<!--                                        <span>￥25.00</span>-->
<!--                                        <span>元</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="foot">
            <div class="timeBtn">
                <div :class="timeBtnIndex==1?'timeBtn_click':''" @click="timeBtnIndex=1">近30天</div>
                <div :class="timeBtnIndex==2?'timeBtn_click':''" @click="timeBtnIndex=2">近1年</div>
            </div>
            <div class="EChartsFaDiv">
                <div class="EChartsBox" ref="EChartsBox"></div>
            </div>
        </div>
    </div>
</template>
<script>
    const colorList = ["#1b047d", '#73DDFF', '#05f129', '#0a8026', '#9E87FF'];
    let ecs = null
    export default {
        name:'home',
        created(){
        },
        data(){
            return{
                timeBtnIndex:1,//按钮index
                EChartsFlag:this.$store.state.Home.EChartsFlag,
                EChartsOption: {
                    backgroundColor: '#fff',
                    title: {
                        text: '',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 100
                        },
                        left: 'center',
                        top: '5%'
                    },
                    legend: {
                        icon: 'circle',
                        top: '0%',
                        right: 'center',
                        itemWidth: 6,
                        itemGap: 20,
                        textStyle: {
                            color: '#556677'
                        },
                        selected: {'成员增加数量': true,'提报员工数量':false,'未入职员工数量':false}
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            label: {
                                show: true,
                                backgroundColor: '#fff',
                                color: '#556677',
                                borderColor: 'rgba(0,0,0,0)',
                                shadowColor: 'rgba(0,0,0,0)',
                                shadowOffsetY: 0
                            },
                            lineStyle: {
                                width: 0
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#5c6c7c'
                        },
                        padding: [10, 10],
                        extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
                    },
                    grid: {
                        top: '10%',
                        left:'3%',
                        right:'3%',
                        bottom:'10%'
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['2023-02-17', '2023-02-18', '2023-02-19', '2023-02-20', '2023-02-21', '2023-02-22', '2023-02-23'],

                        axisLine: {
                            show:true,
                            lineStyle: {
                                color: '#f4f4f4'
                            }
                        },
                        splitLine:{
                            show:false,//刻度尺对应竖线
                            color:'#f0f0f0'
                        },
                        axisTick: {
                            show: true,
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            interval: "auto",
                            textStyle: {
                                color: '#556677'
                            },
                            // 默认x轴字体大小
                            fontSize: 12,
                            // margin:文字到x轴的距离
                            margin: 15,
                            align:'center'
                        },
                        axisPointer: {
                            label: {
                                // padding: [11, 5, 7],
                                padding: [0, 0, 10, 0],
                                margin: 15,
                                // 移入时的字体大小
                                fontSize: 12,
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0.7,
                                        color: '#fff' // 0% 处的颜色
                                    }, {
                                        // offset: 0.9,
                                        offset: 0.8,
                                        color: '#b7b7ce' // 0% 处的颜色
                                    }, {
                                        offset: 0.9,
                                        color: '#02fabf' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: '#02f4fc' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            }
                        },

                        boundaryGap: false
                    }],
                    yAxis: [{
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        min: 0,
                        max: 10,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        // 分割线
                        splitLine: {
                            lineStyle: {
                                type: "dashed",
                                color: "#E9E9E9"
                            }
                        }
                    },
                        {
                            type: 'value',
                            // position: 'right',
                            axisTick: {
                                show: false
                            },
                            min: 0,
                            max: 80,
                            axisLabel: {
                                show: true,
                                textStyle: {
                                    color: '#070707'
                                },
                                interval: "auto",
                                formatter: '{value}%'//右边Y轴刻度值
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#DCE2E8'
                                }
                            },
                            splitLine: {
                                show: false
                            }
                        }],
                    series: [
                        {
                            name: '会员增加数量',
                            type: 'line',
                            stack: "会员增加数量",
                            data: [1,0,5,3,1,8,6],
                            label: {
                                show: true
                            },
                            symbolSize: 1,
                            symbol: 'circle',
                            smooth: true,
                            yAxisIndex: 0,
                            showSymbol: false,
                            lineStyle: {
                                width: 2,
                                color: new this.$EC.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#03a2fa'
                                },
                                    {
                                        offset: 1,
                                        color: '#03a2fa'
                                    }
                                ]),

                            },
                            areaStyle: {
                                normal: {
                                    type: "default",
                                    color: new this.$EC.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [{
                                            offset: 0,
                                            color: "#03a2fa",
                                        },
                                            {
                                                offset: 0.5,
                                                color: "#65b3e2",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(194,209,217,0)",
                                            },
                                        ],
                                        false
                                    ),
                                },
                            },
                            itemStyle: {
                                normal: {
                                    color: '#03a2fa',
                                    borderColor: '#03a2fa'
                                }
                            }
                        },
                        {
                            name: '每日发放金额',
                            type: 'line',
                            stack: "每日发放金额",
                            label: {
                                show: true
                            },
                            data: [0, 4, 3, 9, 1, 5, 2],
                            symbolSize: 1,
                            yAxisIndex: 0,
                            symbol: 'circle',
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 2,
                                color: new this.$EC.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: '#02f618'
                                },
                                    {
                                        offset: 1,
                                        color: '#02f618'
                                    }
                                ]),

                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[2],
                                    borderColor: colorList[2]
                                }
                            },
                            areaStyle: {
                                normal: {
                                    type: "default",
                                    color: new this.$EC.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [{
                                            offset: 0,
                                            color: "rgb(73,234,111)",
                                        },
                                            {
                                                offset: 0.5,
                                                color: "rgba(156,205,170,0.5)",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(88,197,255,0)",
                                            },
                                        ],
                                        false
                                    ),
                                },
                            },

                        },
                    ]
                },
                xiaLaLis1:[
                    {name:'入职员工数量',id:0,data:[1,2,5,3,1,8,6]},{name:'在职员工数量',id:1,data:[1,0,5,3,1,8,6]},{name:'提报员工数量',id:2,data:[1,2,7,3,1,9,6]}
                ],
                xiaLaLis2:[
                    {name:'离职员工数量',id:0,data:[0,3,2,4,1,8,6]},{name:'未入职员工数量',id:1,data:[1,2,5,3,1,4,6]}
                ],
                //核心数据
                coreList:[
                    {img:'core1',name:'注册会员数',num:98,uni:'人'},
                    {img:'core2',name:'普通会员',num:98.00,uni:'人'},
                    {img:'core3',name:'铂金会员',num:98.00,uni:'人'},
                    {img:'core4',name:'钻石会员',num:1000,uni:'人'},
                ],
                //快速入口列表
                inletList:[
                    {img:'fast1',name:'会员列表',path:'/worktable/vip/list'},
                    {img:'fast2',name:'会员录入',path:'/worktable/vip/grade'},
                    // {img:'fast3',name:'一键发薪',path:'/payroll/keyPayroll'},
                    // {img:'fast4',name:'资产管理',path:'/payroll/propertyMng'},
                    // {img:'fast5',name:'财务审批',path:'/payroll/financeApproval'}
                ]
            }
        },
        methods:{
            EChartsOpen(){
                ecs = this.$EC.init(this.$refs.EChartsBox)
                ecs.setOption(this.EChartsOption)
            },
            WindowResize(){
                ecs.resize()
            },
        },
        watch:{
            'EChartsFlag'(){
                if(this.EChartsFlag){
                    this.EChartsOpen()
                }else{
                    ecs.dispose()
                }
            }
        },
        mounted(){
            if(this.EChartsFlag){
                this.EChartsOpen()
            }
            window.addEventListener('resize',this.WindowResize)
        },
        beforeDestroy(){
            window.removeEventListener('resize',this.WindowResize)
            ecs.dispose()
        }
    }
</script>
<style scoped lang="scss">
    .home {
        width: 100%;
        height: 100%;
        padding:25px;
        padding-bottom: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .el-row{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
       .el-col, .el-row{
            padding: 0 !important;
        }
        .el-row {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .header{
            width: 100%;
            .colClass{
                background: white;
                padding: 21px 36px;
                box-sizing: border-box;
                border-radius: 9px;
            }
            .rowTitle{
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 0, 0, 1);
            }
            .imgText1{
                margin-top: 15px;
                .imgText1Li{
                    display: flex;
                    align-items: center;
                    img{
                        width: 16px;
                        height: 16px;
                    }
                    span{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(115, 115, 115, 1);
                        margin-left: 6px;
                    }
                }
                .imgText1Num{
                    margin-top: 25px;
                    span:nth-of-type(1){
                        font-size: 24px;
                        font-weight: 700;
                        color: rgba(13, 13, 13, 1);
                    }
                    span:nth-of-type(2){
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(115, 115, 115, 1);
                        margin-left: 3px;
                    }
                }
            }
            .imgText2{
                margin-top: 40px;
                .el-col-5 {
                    width: 20%;
                }
                .imgText2Li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor:pointer;
                    img{
                        width: 45px;
                        height: 45px;
                    }
                    div:nth-of-type(1){
                        margin-top: 11px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                }
                .imgText2Li:hover{
                    img{
                        width: 47px;
                        height: 47px;
                    }
                    div:nth-of-type(1){
                        margin-top: 11px;
                        font-size: 14px;
                        font-weight: 900;
                        color: rgb(11, 15, 101);
                    }
                }
            }
            .textList{
                margin-top: 15px;
                .textLi{
                    span{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(115, 115, 115, 1);
                        white-space: nowrap;
                    }
                }
                .textLiNum{
                    margin-top: 25px;
                    span{
                        font-size: 24px;
                        font-weight: 700;
                        color: rgba(13, 13, 13, 1);
                    }
                }
            }
            .balance{
                margin-top: 40px;
                display: flex;
                align-items: center;
                .balanceInfo{
                    margin-left: 8px;
                    .balanceLogo{
                        display: flex;
                        align-items: center;
                        span{
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(87, 87, 87, 1);
                        }
                        img{
                            margin-left: 8px;
                            cursor:pointer;
                        }
                    }
                    .balanceNum{
                        margin-top: 8px;
                        span:nth-of-type(1){
                            font-size: 18px;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 1);
                        }
                        span:nth-of-type(2){
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(89, 89, 89, 1);
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }
    .foot{
        flex: 1;
        padding: 20px 0 0 0;
        position: relative;
        .timeBtn{
            position: absolute;
            right: 70px;
            top: 25px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(219, 219, 219, 1);
            z-index: 999999;
            padding: 5px;
            box-sizing: border-box;
            &_click{
                background: rgba(196, 214, 255, 0.37);
            }
            div{
                padding: 10px 30px;
                border-radius: 10px;
            }
            div:hover{
                cursor: pointer;
            }
        }
    }
    .EChartsFaDiv {
        width: 100%;
        height: 100%;
        opacity: 1;
        border-radius: 9px;
        background: rgba(255, 255, 255, 1);
        padding: 24px 16px !important;
        box-sizing: border-box;
    }
    .EChartsBox{
        height: 100%;
        width: 100%;
    }

</style>
