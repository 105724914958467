<template>
    <div class="pageMain">
        <div class="tableSearch">
            <div class="fromHeader">
                <div>角色列表</div>
                <!--     头   添加角色抽屉-->
                <div class="drawer">
                    <el-drawer
                            title="添加角色"
                            :visible.sync="drawer"
                            direction="rtl"
                            :show-close="false"
                            :before-close="handleClose">
                        <div class="drawer-main">
                            <el-form  label-width="80px" :model="formLabelAlign.systemRole"  ref="refFrom" :rules="ruleForm" label-position="right" style="color: black">
                                <el-form-item label="名称:"  prop="name">
                                    <div style="width: 70%">
                                        <el-input v-model="formLabelAlign.systemRole.name" placeholder="请输入" style="width: 200px"></el-input>
                                    </div>
                                </el-form-item>
                                <div class="tree">
                                    <div style="margin-bottom: 10px;">权限</div>
                                    <el-tree
                                            :data="treeData"
                                            show-checkbox
                                            node-key="id"
                                            ref="tree"
                                            :props="defaultProps">
                                    </el-tree>
                                </div>
                            </el-form>
                        </div>
                        <div class="drawer-foot">
                            <el-button @click="drawer=false">取消</el-button>
                            <el-button type="primary" @click="getCheckedKeys">确定</el-button>
                        </div>
                    </el-drawer>
                </div>
                <!--     尾   添加角色抽屉-->
                <!--     头   修改角色抽屉-->
                <div class="drawer">
                    <el-drawer
                            title="修改角色"
                            :visible.sync="drawer2"
                            direction="rtl"
                            :show-close="false"
                            :before-close="handleClose">
                        <div class="drawer-main">
                            <el-form  label-width="80px" :model="alterForm.systemRole"   ref="refFrom" :rules="ruleForm" label-position="right" style="color: black">
                                <el-form-item label="名称:" prop="name">
                                    <div style="width: 70%">
                                        <el-input v-model="alterForm.systemRole.name" placeholder="请输入" style="width: 200px"></el-input>
                                    </div>
                                </el-form-item>
                                <div class="tree">
                                    <div style="margin-bottom: 10px;">权限</div>
                                    <el-tree
                                            :data="treeData"
                                            show-checkbox
                                            node-key="id"
                                            accordion
                                            ref="tree2"
                                            :default-expanded-keys="defList2"
                                            :props="defaultProps">
                                    </el-tree>
                                </div>
                            </el-form>
                        </div>
                        <div class="drawer-foot">
                            <el-button @click="drawer2=false">取消</el-button>
                            <el-button type="primary" @click="getCheckedKeys2">保存</el-button>
                        </div>
                    </el-drawer>
                </div>
                <!--     尾   修改角色抽屉-->
                <!--     头   详情弹出窗-->
                <div class="popup">
                    <el-dialog title="角色详情" :visible.sync="dialogFormVisible">
                        <div class="scroll" ref="scroll">
                            <el-form v-if="detailForm">
                                <el-form-item label="角色名称" :label-width="formLabelWidth">
                                    <div style="width: 70%">
                                        <el-input v-model="detailForm.systemRole.name" autocomplete="off" readonly></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="创建人"  :label-width="formLabelWidth">
                                    <div style="width: 70%">
                                        <el-input value="admin" autocomplete="off" readonly ></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="创建时间"  :label-width="formLabelWidth">
                                    <div style="width: 70%">
                                        <el-input :value="$iApi.moment(detailForm.systemRole.createtime,'YYYY-MM-DD HH:mm:ss')" autocomplete="off" readonly></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="权限" :label-width="formLabelWidth">
                                    <div>
                                        <el-tree
                                                :data="treeData2"
                                                show-checkbox
                                                node-key="id"
                                                ref="tree3"
                                                :default-expanded-keys="defList"
                                                :props="defaultProps">
                                        </el-tree>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-dialog>
                </div>
                <!--     尾   详情弹出窗-->
                <div class="newAddBtn"  @click="openDrawer">
                    <button>
                        <img src="../../../assets/account/bi.png" alt=""><div>添加角色</div>
                    </button>
                </div>
            </div>
            <!--头部查询功能-->
            <div class="fromLabels">
                <div class="labelDiv">
                    <div class="label_name">角色名称</div>
                    <template>
                        <el-input  placeholder="请输入角色名称" v-model="tableReq.name" @change="changeSearchName"></el-input>
                    </template>
                </div>
                <div class="btnLabel_Li">
                    <button @click="getRoleTable">
                        <img src="../../../assets/button/搜索icon.png" alt=""><div style="margin-left: 8px">搜索</div>
                    </button>
                    <button style="margin-left: 10px" @click="searchReset">
                        <img src="../../../assets/button/重置icon.png" alt=""><div style="margin-left: 8px">重置</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="tableMain">
            <div class="tableBox">
                <div class="scroll">
                    <template>
                        <el-table
                                :data="tableData.data"
                                style="width: 100%"
                                :header-cell-style="{ background: '#F5F6FA', textAlign: 'center' }"
                                :cell-style="{ textAlign: 'center' }"
                                height="100%"
                                v-loading="loading"
                                element-loading-text="拼命加载中">
                            <el-table-column prop="companyId" label="序号" width="100" sortable >
                                <template slot-scope="scope">
                                    {{scope.$index+1}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="workAddress" label="角色名称">
                                <template slot-scope="scope">
                                    {{scope.row.systemRole.name}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建人" width="" sortable>
                                <template>
                                    {{"admin"}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" width="" sortable>
                                <template slot-scope="scope">
                                    {{scope.row.systemRole&&scope.row.systemRole.createtime?$iApi.moment(scope.row.systemRole.createtime,'YYYY-MM-DD HH:mm:ss'):'-- --'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="修改时间" width="" sortable>
                                <template slot-scope="scope">
                                    {{$iApi.moment(scope.row.systemRole.updatetime,'YYYY-MM-DD HH:mm:ss')}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="operate" label="操作" width="140">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="openDrawer2(scope.row)">修改</el-button>
                                    <el-button type="text" size="small" @click="delRole(scope.row)">删除</el-button>
                                    <el-button type="text" size="small" @click="openDetail(scope.row)">详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
                <!--          分页标签-->
                <div class="fenye">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableReq.pageNo"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableReq.pageSize"
                            layout="total, prev, pager, next, sizes"
                            :total="tableData.count">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import {getAllAuthority,getRoleTable,addRole,delRole} from "../../../api/authorityManage";
    export default {
        name: "areaConfigDefault",
        data() {
            return {
                value:'',//待定属性
                options:[],
                //暂留
                searchBody:{
                    searFlag:false
                },
                tableData: {
                    data: [],
                    count:0
                },//表格数据
                tableReq:{
                    name:'',
                    pageNo:1,
                    pageSize:20,
                },
                //发布弹窗对象
                drawer: false,//抽屉控制
                drawer2: false,//抽屉控制
                dialogFormVisible: false,//弹窗控制
                formLabelAlign: {//添加角色表单
                    systemRole:{name:''},
                    menuIdList:[],
                },
                ruleForm:{
                    name:[
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ]
                },
                alterForm: {//修改角色表单
                    systemRole:{name:'',id:''},
                    menuIdList:[],
                },
                detailForm:null,
                reset:false,//重置
                formLabelWidth: '120px',//
                loading: false,
                paging:{
                    pageNo:1,
                    pageSize:20,
                    limitCount:0
                },
                defaultProps: {
                    children: 'detail',
                    label: 'name'
                },
                treeData:[],//树数据
                treeData2:[],//详情树数据
                defList:[], //详情树选中
                defList2:[],//修改数选中
            };
        },
        created(){
            this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
            this.treeData=JSON.parse(localStorage.getItem('paas_routerMenu')).detail
            const temp = JSON.parse(JSON.stringify(this.treeData))
            this.treeData2=  this.recursion(temp)
            this.getRoleTable().then(()=>{
            })
        },
        methods:{
            recursion(arr){
                  arr.forEach(a=>{
                    a.disabled=true
                    if(a.detail&&a.detail.length){
                        this.recursion(a.detail)
                    }
                })
                return arr
            },
            changeSearchName(v){
                if(v&&String(v).trim().length){
                    this.searchBody.searFlag=true
                }
            },
            getRoleTable(){
              this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                if(this.searchBody.searFlag){
                    this.tableReq.pageNo=1
                    this.searchBody.searFlag=false
                }
                return new Promise((resolve => {
                    getRoleTable(this.tableReq).then(res=>{
                        this.$loading().close()
                        if(res.code==1000){
                            this.tableData=res.data
                        }
                        resolve()
                    }).catch(()=>{
                        resolve()
                    })
                }))
            },
            openDrawer(){
                this.drawer = true
                this.$nextTick(()=>{
                    this.$refs['refFrom'].clearValidate()
                })
            },
            openDrawer2(item){
                console.log(item)
                this.drawer2 = true
                let defList=[]
                if(item.list){
                    let tempArr = item.list.map(item=>item.id)
                    this.treeData.forEach(item=>{
                        tempArr.forEach(dItem=>{
                            if(dItem==item.id&&!item.detail){
                                 defList.push(dItem)
                            }
                        })
                        if(item.detail){
                            item.detail.forEach(vItem=>{
                                tempArr.forEach(dItem=>{
                                    if(dItem==vItem.id&&!vItem.detail){
                                        defList.push(dItem)
                                    }
                                })
                                if(vItem.detail){
                                    vItem.detail.forEach(wItem=>{
                                        tempArr.forEach(dItem=>{
                                            if(dItem==wItem.id&&!wItem.detail){
                                                defList.push(dItem)
                                            }
                                        })
                                    })
                                }
                            })
                        }
                    })
                }
                this.alterForm.systemRole.name=item.systemRole.name
                this.alterForm.systemRole.id=item.systemRole.id
                this.$nextTick(()=>{
                    this.$refs.tree2.setCheckedKeys([])
                    this.$refs.tree2.setCheckedKeys(defList)
                })
                this.$nextTick(()=>{
                    this.$refs['refFrom'].clearValidate()
                })
            },
            openDetail(item){
                this.dialogFormVisible = true
                this.detailForm=item
                let defList=[]
                if(item.list){
                    let tempArr = item.list.map(item=>item.id)
                    this.treeData.forEach(item=>{
                        tempArr.forEach(dItem=>{
                            if(dItem==item.id&&!item.detail){
                                defList.push(dItem)
                            }
                        })
                        if(item.detail){
                            item.detail.forEach(vItem=>{
                                tempArr.forEach(dItem=>{
                                    if(dItem==vItem.id&&!vItem.detail){
                                        defList.push(dItem)
                                    }
                                })
                                if(vItem.detail){
                                    vItem.detail.forEach(wItem=>{
                                        tempArr.forEach(dItem=>{
                                            if(dItem==wItem.id&&!wItem.detail){
                                                defList.push(dItem)
                                            }
                                        })
                                    })
                                }
                            })
                        }
                    })
                }
                this.$nextTick(()=>{
                    this.$refs.tree3.setCheckedKeys([])
                    this.$refs.tree3.setCheckedKeys(defList)
                })
            },
            delRole(item){
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    delRole(item.systemRole.id).then(res=>{
                        if(res.code==1000){
                            this.$message.success('删除成功')
                            this.getRoleTable()
                        }else{
                            this.$message.error('删除失败')
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            handleSizeChange(val){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.searchBody.searFlag=true
                this.tableReq.pageSize=val
                this.getRoleTable()
            },
            handleCurrentChange(val){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.tableReq.pageNo=val
                this.getRoleTable()
            },
            handleClose(done) {
               done();
            },
            //添加角色
            getCheckedKeys(){
                this.$refs['refFrom'].validate((valid) => {
                    if (valid) {
                         this.$loading({
                            lock: true,
                            text: '正在创建',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.1)'
                        });
                        this.formLabelAlign.menuIdList=this.$refs.tree.getCheckedNodes(false,true).map(item=>item.id)
                        addRole(this.formLabelAlign).then(res=>{
                            this.$loading().close()
                            if(res.code==1000){
                                this.$message.success('创建成功')
                                this.getRoleTable()
                            }else{
                                this.$message.error('创建失败')
                            }
                            this.drawer = false
                        }).catch(()=>{
                            this.$loading().close()
                        })
                    } else {
                        return false;
                    }
                });
            },
            getCheckedKeys2(){
                this.$refs['refFrom'].validate((valid) => {
                    if (valid) {
                         this.$loading({
                            lock: true,
                            text: '正在保存',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.1)'
                        });
                        this.alterForm.menuIdList=this.$refs.tree2.getCheckedNodes(false,true).map(item=>item.id)
                        addRole(this.alterForm).then(res=>{
                            this.$loading().close()
                            if(res.code==1000){
                                this.$message.success('修改成功')
                                this.getRoleTable()
                            }else{
                                this.$message.error('修改失败')
                            }
                            this.drawer2 = false
                        }).catch(()=>{
                            this.$loading().close()
                        })
                    } else {
                        return false;
                    }
                });

            },
            searchReset(){
                this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.tableReq.name=''
                this.getRoleTable()
            },

        },
        computed:{


        },
        watch:{

        },
    };
</script>

<style scoped>
    .pageMain {
        width: 100%;
        height: 100%;
    }
    .tableSearch {
        width: 100%;
        height: 170px;
        padding: 35px 54px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .fromLabels {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    .el-select {
        width: 240px;
        height: 31px;
        opacity: 1;
        box-sizing: border-box;
        width: 100%;
    }
    ::v-deep .el-input__inner {
        height: 31px;
        line-height: 31px;
    }
    ::v-deep  .el-select .el-input .el-select__caret {
        line-height: 31px;
    }
    .el-input__inner {
        border-radius: 0;
        background: #f4f4f5;
    }

    ::v-deep  .el-select .el-input .el-select__caret {
        color: rgba(100, 102, 107, 1);
    }
    .labelDiv {
        margin-right: 12px;
        position: relative;
    }
    .btnLabel_Li {
        margin-left: 48px;
    }
    .btnLabel_Li > img:hover {
        cursor: pointer;
    }
    .btnLabel_Li {
        width: 193px;
        height: 35px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 36px;
    }
    .newAddBtn {
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 28px;
    }
    .fromHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /*______________________________________*/
    .tableMain {
        width: 100%;
        height: calc(100% - 170px);
        background: #f5f6fa;
        padding: 0px 20px;
        box-sizing: border-box;
    }
    .tableBox {
        width: 100%;
        height: calc(100%);
        background: white;
        position: relative;
    }
    .tableBox>.scroll{
        width: 100%;
        height: calc(100% - 52px);
        overflow: auto;
    }
    ::v-deep .el-table tr {
        background: transparent;
    }
    ::v-deep .el-table th.el-table__cell {
        background: transparent;
    }
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
    }
    ::v-deep  .el-table .sort-caret.ascending {
        border-bottom-color: #409eff;
        top: 5px;
    }
    ::v-deep  .el-table .sort-caret.descending {
        border-top-color: #409eff;
        bottom: 7px;
    }
    ::v-deep  .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #c0c4cc;
    }
    ::v-deep  .el-table .descending .sort-caret.descending {
        border-top-color: #c0c4cc;
    }
    .label_name {
        position: absolute;
        top: 7px;
        left: 16px;
        z-index: 2;
        color: rgba(138, 139, 145, 1);
        font-size: 12px;
    }
    ::v-deep .fromLabels .el-input__inner {
        width: 100%;
        padding-left: 70px !important;
        box-sizing: border-box;
        font-size: 12px;
        /*text-align: center;*/
    }

    .el-select-dropdown__item {
        text-align: center;
        /*padding-left: 50px;*/
    }
    /*发布属性*/
    ::v-deep  .popup .el-dialog{
        width: 30%;
        min-width:600px;
        border-radius: 10px;
        /*margin-top: 20vh !important;*/
    }
    ::v-deep  .popup .el-dialog__title{
        width: 64px;
        height: 24px;
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        margin-left: 40px;
        left: -8065px;
        top: -3589px;
    }
    ::v-deep  .popup .el-dialog__title:before{
        content:'';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background:rgba(31, 120, 255, 1)  ;
        position: absolute;
        top: 27px;
        left: 40px;
    }
    ::v-deep  .popup .el-dialog__body{
        padding: 10px 20px;
    }

    ::v-deep  .popup .el-form-item__label{
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        vertical-align: top;
    }
    ::v-deep  .popup .el-form-item{
        margin-bottom: 15px;
    }
    ::v-deep  .popup .el-col-1{
        margin: 0 5px;
        text-align: center;
        color: black;
    }
    ::v-deep  .popup .el-col-2{
        width: 1px;
    }
    ::v-deep  .popup .el-col-11{
        width: 130px;
    }
    ::v-deep  .popup .el-input__prefix{
        display: none;
    }
    ::v-deep  .popup .el-input--suffix .el-input__inner{
        /*height: 31px !important;*/
    }
    ::v-deep  .popup .el-input--suffix .el-input__inner{
        padding: 0 15px;
    }

    ::v-deep  .popup .el-dialog__footer{
        width: 100%;
        position: relative;
        bottom: 10px;
    }

    ::v-deep  .popup  .dialog-footer .el-button:nth-of-type(1){
        background-size: cover;
        border-radius: 5px;
        color: white;
        width: 265px;
        height: 61px;
        opacity: 1;
        background: rgba(31, 120, 255, 1);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    ::v-deep  .popup .dialog-footer .el-button:nth-of-type(2){
        color: rgba(31, 120, 255, 1);
        border-radius: 5px;
        margin-left: 38px;
        width: 205px;
        height: 61px;
        opacity: 1;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
    }
    ::v-deep  .popup .scroll{
        max-height: 60vh;
        overflow-y: auto;
        padding-bottom: 10px;
        box-sizing: border-box;
        position: relative;
    }
    ::v-deep  .popup .scroll .el-input__inner{
        height: 31px;
        line-height: 31px;
    }
    .dialog-footer{
        text-align: center;
    }
    ::v-deep  .popper__arrow::after{
        display: none;
    }
    ::v-deep  .popper__arrow{
        display: none !important;
    }
    ::v-deep  .popup .el-popper{
        margin-top: 0;
    }

    /*  按钮*/
    .btnLabel_Li>button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btnLabel_Li img{
        width: 13px;
    }
    .btnLabel_Li>button:nth-of-type(1){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid  rgba(73, 119, 252, 1);
    }
    .btnLabel_Li>button:nth-of-type(2){
        width: 86px;
        height: 35px;
        opacity: 1;
        border-radius: 7px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 0.5px solid rgba(73, 119, 252, 1);
        font-size: 12px;
        font-weight: 400;
        color: rgba(73, 119, 252, 1);
    }
    /*发布职位*/
    .newAddBtn>button{
        width: 123px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(73, 119, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0.5px solid rgba(73, 119, 252, 1) ;
    }
    .newAddBtn img{
        width: 15px;
        height: 13.83px;
        opacity: 1;
        margin-right: 7px;
    }

    .fenye{
        position: absolute;
        bottom: 10px;
        right:10px;
        height: 32px;
        text-align: right;
    }
    /*分页bug解决*/
    ::v-deep  .fenye .el-input__inner{
        padding: 0 15px !important;
    }
    ::v-deep  .scroll .el-table .el-table__cell{
        padding: 4px 0 !important;
    }
    .btnLabel_Li>button:hover{
        cursor: pointer;
    }
    .newAddBtn>button:hover{
        cursor: pointer;
    }

</style>
<!--搜搜区域 名称控件-->
<style scoped>
    .labelDiv  .el-input__suffix {
        display: flex;
        align-items: center;
    }
</style>
<!--搜索区域 时间控件-->
<style scoped>
    .labelDiv:nth-of-type(3){
        width:25vw;
        max-width: 415px;
    }
    .labelDiv:nth-of-type(3) .el-input__inner{
        background: white;
    }
    .labelDiv:nth-of-type(3) .el-range-editor.el-input__inner{
        border-radius:4px;
    }
    ::v-deep  .labelDiv .el-date-editor .el-range__close-icon{
        line-height: 23px;
    }
    ::v-deep  .labelDiv .el-date-editor .el-range-input{
        width: 8vw;
        max-width: 130px;
    }
</style>
<!--抽屉css-->
<style scoped>
    ::v-deep  .drawer #el-drawer__title{
        color: black;
        position: relative;
        font-size: 16px;
    }
    ::v-deep  .drawer .el-drawer__header{
        margin-bottom: 25px;
    }
    ::v-deep .drawer .el-drawer__header>:first-child{
        font-weight: 400;
    }
  ::v-deep   .drawer .el-drawer{
        padding: 10px;
        box-sizing: border-box;
    }
::v-deep  .drawer #el-drawer__title>span:before{
        content:'';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background:rgba(31, 120, 255, 1)  ;
        position: absolute;
        top: 27px;
        left: 2px;
    }
::v-deep  .drawer .el-form-item{
        margin-bottom: 10px;
}
 ::v-deep    .drawer .el-form-item__label{
            font-size: 14px;
            color: black;
    }
    ::v-deep  .drawer .el-input{
        width: 100%;
    }
    ::v-deep  .drawer .el-input>.el-input__inner{
        border: 1px solid rgba(230, 230, 230, 1);
    }

    ::v-deep  .drawer .checkItem .el-checkbox{
        width: 90px;
    }
    ::v-deep  .drawer .checkItem .el-checkbox__label{
        color: rgba(115, 115, 115, 1);
    }
   ::v-deep  .drawer .el-drawer__body{
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
       width: 100%;
    }
   .drawer-main{
       height: calc(100% - 50px);
       overflow-y: scroll;
       width: 100%;
   }
   .drawer-main::-webkit-scrollbar{
       display: none;
   }
    .drawer-foot{
        width: 100%;
        text-align: right;
        padding: 5px 15px;
        box-sizing: border-box;
    }
    .drawer-foot>button:nth-of-type(1){
        width: 110px;
        background: #F4F4F5;
        border-color: #F4F4F5;
    }
    .drawer-foot>button:nth-of-type(2){
        margin-left: 20px;
        width: 110px;
        background: #2A55E5;
        border-color: #2A55E5;
    }
    .drawer-foot>button:nth-of-type(2):hover{
        color: #59bfe2;
    }
/*    树*/
    .tree{
        font-size: 16px;
        position: relative;
        padding: 0 20px;
        margin-top: 20px;
    }
    .tree:before{
        content:'';
        width: 6px;
        height: 12px;
        border-radius: 65px;
        background: rgba(42, 85, 229, 1);
        position: absolute;
        top: 5px;
        left: 10px;
    }
</style>
