<template>
  <div class="home">
    <el-container>
      <el-header height="64px">
        <Header msg="/permissions"></Header>
      </el-header>
      <el-container>
        <el-aside width="238px">
          <Aside  name="/permissions"></Aside>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  components: {
    Header:()=>import('../components/Header'),
    Aside:()=>import('../components/Aside'),
  },
};
</script>
<style>

</style>
