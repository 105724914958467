<template>
    <div class="page">
        <div class="header">
            <el-menu
                :default-active="vue"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                text-color="black">
                <el-menu-item :index="activeIndex1">待审核</el-menu-item>
                <el-menu-item :index="activeIndex2">已审核</el-menu-item>
            </el-menu>
            <div class="otherDiv">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item>
                        <el-date-picker
                            prefix-icon=" "
                            v-model="searchForm.date"
                            type="daterange"
                            range-separator="-"
                            value-format="timestamp"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.companyid" placeholder="选择客户公司"
                            @change="changeSelectedCompany">
                            <el-option
                                v-for="item in companyList"
                                :key="item.companyname"
                                :label="item.companyname"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="main">
            <!-- <component :is="vue" ref="vue"></component> -->
            <end ref="vue"></end>
        </div>
    </div>
</template>

<script>
import moment from "@/util/js/moment";
import { getCompanyList } from "../../../api/workbenche";

export default {
    name: "account",
    data() {
        return {
            activeIndex1: "start",
            activeIndex2: 'end',
            vue: 'start',
            companyList: [],
            searchForm: {
                date: [moment().startOf('month'), moment()],
                companyid: '',
                selectedCompany: {}
            }
        };
    },
    components: {
        end: () => import('./list/end'),
    },
    created(){
        
    },
    methods: {
        onSearch() {
            this.$ELoading();
            console.log('this.form', this.searchForm)
            this.$refs['vue'].listReq.data.createtime = this.searchForm.date;
            this.$refs['vue'].listReq.userid = this.searchForm.companyid;
            this.$refs['vue'].getList().then(() => {
                this.$EClose()
            })
        },
        changeSelectedCompany(val){
            console.log('val', val)
            if (val) {
                this.searchForm.selectedCompany = this.companyList.find(i => i.id === val);
            } else {
                this.searchForm.selectedCompany = {};
            }
        },
        //添加成功调取列表
        addGetList(v) {
            this.$ELoading();
            getCompanyList({
                data: {
                    insurancestatus: '3'
                }
            }).then((res) => {
                console.log('res', res)
                this.$refs['vue'].listReq.data.createtime = this.searchForm.date;
                if (res?.data?.list?.length > 0) {
                    this.companyList = res?.data?.list || [];
                    this.searchForm.companyid = res?.data?.list?.[0].id;
                    this.searchForm.selectedCompany = res?.data?.list?.[0];
                    this.$refs['vue'].listReq.userid = res?.data?.list?.[0].id;
                    this.$refs['vue'].getList().then(() => {
                        this.$EClose()
                    })
                } else {
                    this.$EClose();
                }
            })
        },
        handleSelect(key, keyPath) {
            this.vue = key;
            if (key === 'start') {
                this.$refs['vue'].listReq.data.status = ['0']
            } else {
                this.$refs['vue'].listReq.data.status = ['1', '2', '3']
            }
            this.$ELoading();
            if (!this.$refs['vue'].listReq.userid) {
                this.$EClose();
                return;
            }

            this.$refs['vue'].getList().then(() => {
                this.$EClose()
            })
        },
    },
};
</script>
<style scoped>
.page {
    width: 100%;
    height: 100%;
}

.el-form--inline .el-form-item {
    margin-bottom: 0;
}

.header {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: solid 1px #e6e6e6;
}

.el-menu {
    display: flex;
    align-items: flex-end;
}

.el-menu--horizontal>.el-menu-item {
    height: 45px;
}

.el-menu.el-menu--horizontal {
    padding-left: 86px !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid rgba(42, 85, 229, 1);
}

.main {
    width: 100%;
    height: calc(100% - 57px);
}

.el-dropdown-menu__item,
.el-menu-item {
    padding: 0;
    margin: 0 20px !important;
}

.otherDiv {
    padding: 0 20px;
    box-sizing: border-box;
}

.otherDiv button {
    width: 123px;
    height: 39px;
    border-radius: 4px;
    background: rgba(73, 119, 252, 1);
    color: rgba(255, 255, 255, 1);
}

.otherDiv button:hover {
    opacity: .9;
}
</style>