import { render, staticRenderFns } from "./dictConfig.vue?vue&type=template&id=88efb0ea&scoped=true"
import script from "./dictConfig.vue?vue&type=script&lang=js"
export * from "./dictConfig.vue?vue&type=script&lang=js"
import style0 from "./dictConfig.vue?vue&type=style&index=0&id=88efb0ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88efb0ea",
  null
  
)

export default component.exports