<template>
    <div class="page">
        <div class="header">
            <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    text-color="black">
                <el-menu-item :index="activeIndex">角色管理</el-menu-item>
            </el-menu>
            <div class="line"></div>
        </div>
        <div class="main">
            <component :is="vue"></component>
        </div>
    </div>
</template>

<script>
    export default {
        name: "roleManage",
        data() {
            return {
                activeIndex: "roleManageDefault",
                vue:'roleManageDefault'
            };
        },
        components:{
            roleManageDefault:()=>import('./roleManage/roleManageDefault'),
        },
        methods: {
            handleSelect(key, keyPath) {
                this.vue=key;
            },
        },
    };
</script>
<style scoped>
    .page {
        width: 100%;
        height: 100%;
    }
    .header {
        width: 100%;
        height: 56px;
        box-sizing: border-box;
    }
    .main {
        width: 100%;
        height: calc(100% - 56px);
    }
    .el-menu {
        display: flex;
        align-items: flex-end;
    }
    .el-menu--horizontal > .el-menu-item {
        height: 45px;
    }
    .el-menu.el-menu--horizontal {
        padding-left: 86px !important;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid rgba(42, 85, 229, 1);
    }
    .main {
        width: 100%;
        height: calc(100% - 57px);
    }
    .el-dropdown-menu__item, .el-menu-item{
        padding: 0;
        margin: 0 20px !important;
    }
</style>
