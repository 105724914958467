<template>
  <div class="aside">
    <el-menu
      :default-active="onPath"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      unique-opened
      active-text-color="#2A55E5"
      text-color="#595D5C"
      router>
      <template v-for="(item,i) in routerArr">
        <el-menu-item :index="item.path" :key="i" v-if="!item.children.length">
          <i :class="item.meta.icon"></i>
          <span slot="title">{{item.meta.name}}</span>
        </el-menu-item>
        <el-submenu :index="item.path"  :key="i" v-else>
          <template slot="title">
            <i :class="item.meta.icon"></i>
            <span>{{item.meta.name}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="itemv.path" v-for="(itemv,vi) in item.children" :key="vi">{{itemv.meta.name}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>
<script>
  //区域配置左侧栏
export default {
  name: "Aside",
  props:["name"],
  data() {
    return {
      routerArr:[]
    };
  },
  created() {
    if(!localStorage.getItem('PAAS_AllRouter')){
      return
    }
    JSON.parse(localStorage.getItem('PAAS_AllRouter')).forEach(item=>{
      if(item.path.includes(this.name)){
        let tempArr = item.children
        if(tempArr&&tempArr.length){
          tempArr.forEach(sitem=>{
            // sitem.meta=JSON.parse(sitem.meta)
            sitem.meta=sitem.meta
            if(sitem.children&&sitem.children.length){
              sitem.children.forEach(xitem=>{
                // xitem.meta=JSON.parse(xitem.meta)
                xitem.meta=xitem.meta
              })
            }
          })
        }
        this.routerArr=tempArr
      }
    })
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
  },
  computed:{
    onPath() {
      const route = this.$route;
      const { path,matched } = route;
      return path;
    },
  }
};
</script>
<style scoped>
.aside {
  height: 100%;
}
li>span {
  font-size: 16px;
}
</style>
