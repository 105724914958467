<template>
  <div class="pageMain">
    <div class="tableMain">
        <ETable ref="ETable" :tableData="tableData"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            <template #operate="{rowData:rowData}">
                <el-button type="text" size="small" @click="alterGrade(rowData)">修改</el-button>
                <el-button type="text" size="small" @click="alterGradeStatus(rowData,1,1)" v-if="rowData.status==0">停用</el-button>
                <el-button type="text" size="small" @click="alterGradeStatus(rowData,1,0)" v-if="rowData.status==1">启用</el-button>
                <el-button type="text" size="small" @click="alterGradeStatus(rowData,0,1)">删除</el-button>
            </template>
        </ETable>
    </div>
      <alterGrade ref="alterGrade" :type="1"></alterGrade>
  </div>
</template>

<script>
    import {getVipGradeList,alterVipGrades,getVipGradeTree} from "@/api/workbenche";
    export default {
    name: "end",
    components:{
      alterGrade:()=>import('./component/addGrade')
    },
    data() {
      return {
        //表格列表请求体
        listReq:{
          index:20,
          page:1,
          data:{
              isdelete:0
          }
        },
          tableData: {
              currentPage:0,
              data: [],
              cols:[
                  {label:'等级名称',prop:'name'},
                  {label:'等级说明',prop:'remark'},
              ],
          },
      };
    },
    created() {
        this.$ELoading()
        this.getList().then(()=>{
            this.$EClose()
        })
    },
    methods:{
        //修改等级
        alterGrade(item){
            this.$ELoading()
            //回显功能
            getVipGradeTree(item.id).then(res=>{
                if(res){
                    this.$EClose()
                    if(res.code==1000){
                        const cmp = this.$refs['alterGrade']
                        cmp.tree.forEach((itemE,i)=>{
                            //重置
                            cmp.checkedList[`checkedCities${i+1}`]=[]
                            cmp.checkAllList[`checkAll${i+1}`]=false
                            cmp.indeterminateList[`isIndeterminate${i+1}`]=false
                            itemE.detail?.forEach(itemE2=>{
                                res.data?.forEach(itemE3=>{
                                    if(itemE2.id==itemE3.systemmenuid){
                                        cmp.checkedList[`checkedCities${i+1}`]?.push(itemE3.systemmenuid)
                                    }
                                })
                            })
                            if(cmp.checkedList[`checkedCities${i+1}`].length==itemE.detail?.length){
                                cmp.checkAllList[`checkAll${i+1}`]=true
                            }else if(cmp.checkedList[`checkedCities${i+1}`].length){
                                cmp.indeterminateList[`isIndeterminate${i+1}`]=true
                            }
                            //请求体list
                            cmp.addGradeReq.list=res.data.map(li=>{
                                return {systemmenuid:li.systemmenuid}
                            })
                        })
                        //输入框
                        cmp.addGradeReq.data.id=item.id
                        cmp.addGradeReq.data.name=item.name
                        cmp.addGradeReq.data.remark=item.remark
                        cmp.addGradeReq.data.contracttotal=String(item.contracttotal)
                        cmp.addGradeReq.data.contractunit=String(item.contractunit)
                        //回显规则
                        const list = [item.recharge1,item.recharge2,item.recharge3]
                        cmp.tableData.data=list.map((item,i)=>{
                            item=JSON.parse(item)
                            cmp.gradeForm[`rule${i+1}`]={effective:item.day,price:item.money,switch:item.status?true:false}
                            return  {effective:item.day,switch:i+1,price:item.money}
                        })
                        //打开弹窗
                        cmp.show=true
                    }else{
                        this.$message.error(res.message?res.message:'操作异常')
                    }
                }
            })
        },
        //停用启用删除 type:1启用状态，0删除
        alterGradeStatus(item,type,status){
            const req = {id:item.id,status:type?status:'',isdelete:type?'':status}
            if(status==1){
                this.$EConfirm(`此操作将${type?'停用':'删除'}该会员等级，已开通该等级的会员不受影响、新建时无法选中已${type?'停用':'删除'}的会员等级，是否继续？`,`${type?'停用':'删除'}会员等级`,type?'warning':'error').then(()=>{
                    this.$ELoading()
                    alterVipGrades(this.$req(req)).then(res=>{
                        if(res.code){
                            if(res.code==1000){
                                this.getList().then(()=>{
                                    this.$EClose()
                                })
                                this.$message.success('操作成功')
                            }else{
                                this.$message.error(res.message?res.message:'操作失败')
                                this.$EClose()
                            }
                        }
                    })
                }).catch(()=>{})
            }else if(type==1&&status==0){
                //启用流程
                this.$ELoading()
                alterVipGrades(this.$req(req)).then(res=>{
                    if(res.code){
                        if(res.code==1000){
                            this.getList().then(()=>{
                                this.$EClose()
                            })
                            this.$message.success('操作成功')
                        }else{
                            this.$message.error(res.message?res.message:'操作失败')
                            this.$EClose()
                        }
                    }
                })
            }
        },
        //获取列表
        getList(){
            return new Promise(resolve => {
                getVipGradeList(this.$req(this.listReq)).then(res=>{
                    if(res.code){
                        resolve()
                        if(res.code==1000){
                            this.tableData.data=res.data
                        }
                    }
                })
            })
        },
      handleSizeChange(val) {
        this.listReq.index=val
        this.listReq.page=1
        this.$iApi.paging(this.getList).then(()=>{
            this.$EClose()
        })
      },
      handleCurrentChange(val) {
        this.listReq.page=val
        this.$iApi.paging(this.getList).then(()=>{
            this.$EClose()
        })
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableMain{
    width: 100%;
    height: 100%;
  }
</style>
