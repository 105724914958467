<template>
  <div class="home">
    <el-container>
      <el-header height="64px">
        <Header msg="/worktable"></Header>
      </el-header>
      <el-container>
        <el-aside width="238px">
          <Aside name="/worktable"></Aside>
        </el-aside>
        <el-main>
          <transition>
          <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Aside from "../components/Aside.vue";
export default {
  name: "Home",
  components: {
    Header,
    Aside,
  },
  created(){

  }
};
</script>
<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity .3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
