<template>
  <div class="page">
    <div class="header">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <el-menu-item :index="activeIndex">账号列表</el-menu-item>
      </el-menu>
      <div class="otherDiv">
        <el-button @click="addVip">添加会员等级</el-button>
      </div>
    </div>
    <div class="main">
      <component :is="vue" ref="vue"></component>
    </div>
    <addGrade ref="addGrade"></addGrade>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "default",
      vue:'default'
    };
  },
  components:{
    default:()=>import('./grade/default'),
    addGrade:()=>import('./grade/component/addGrade')
  },
  methods: {
    //添加等级
    addVip(){
      this.$refs['addGrade'].show=true
    },
    handleSelect(key, keyPath) {
      this.vue=key;
    },
    //添加成功调取列表
    addGetList(v){
      this.$refs['vue'].getList().then(()=>{
        this.$EClose()
      })
    },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: solid 1px #e6e6e6;
}
.el-menu {
  display: flex;
  align-items: flex-end;
}
.el-menu--horizontal > .el-menu-item {
  height: 45px;
}
.el-menu.el-menu--horizontal {
  padding-left: 86px !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid rgba(42, 85, 229, 1);
}
.main {
  width: 100%;
  height: calc(100% - 57px);
}
.el-dropdown-menu__item, .el-menu-item{
  padding: 0;
  margin: 0 20px !important;
}
.otherDiv{
  padding: 0 20px;
  box-sizing: border-box;
}
.otherDiv>button{
  width: 123px;
  height: 39px;
  border-radius: 4px;
  background: rgba(73, 119, 252, 1);
  color: rgba(255, 255, 255, 1);
}
.otherDiv>button:hover{
  opacity: .9;
}
</style>
