<template>
  <div class="vuePage">
      <h1 id="target" @mouseenter="moveHandle" :style="(left&&top)!=null?{left:left,top:top}:''" @click="clickTarget">
          首页
          <div class="tip" v-if="this.flag">
              {{tip}}
          </div>
      </h1>
  </div>
</template>
<script>
  export default {
    name:'homeJs',
    data(){
      return{
          target:null,
          left:null,
          top:null,
          tip:'你好菜哦~',
          count:0,//记录次数
          flag:false,
      }
    },
      created() {
       this.target=document.getElementById('target')
      },
      methods:{
          moveHandle(){
              this.count++
              this.left=parseInt(Math.random()*100+Math.random()*1000)+'px'
              this.top =parseInt(Math.random()*100+Math.random()*500)+'px'
              if(this.count%5===0){
                  this.count=0
                  this.flag=true
                  setTimeout(()=>{
                      this.flag=false
                  },5000)
              }
          },
          clickTarget(){
              alert('哎呀，被抓到啦')
          },
      },
  }
</script>
<style scoped>
  .vuePage{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
    #target{
        font-size: 50px;
        color: #8c939d;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all linear .1s;
    }
    .tip{
        position: absolute;
        right: 0;
        top: -20px;
        font-size: 12px;
        color: black;
    }
</style>
