<template>
  <div class="page">
    <div class="header">
      <el-menu
        :default-active="vue"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <el-menu-item :index="activeIndex1">已审核</el-menu-item>
<!--        <el-menu-item :index="activeIndex2">待审核</el-menu-item>-->
      </el-menu>
      <div class="otherDiv">
        <el-button @click="addVip">添加会员</el-button>
      </div>
    </div>
    <div class="main">
      <component :is="vue" ref="vue"></component>
    </div>
    <addVip ref="addVip"></addVip>
  </div>
</template>

<script>
import {getVipGradeList} from "../../../api/workbenche";

export default {
  name: "account",
  data() {
    return {
      activeIndex1: "end",
      activeIndex2:'start',
      vue:'end'
    };
  },
  components:{
    end:()=>import('./list/end'),
    start:()=>import('./list/start'),
    addVip:()=>import('./list/component/addVip')
  },
  methods: {
    //添加会员
    addVip(){
      const req = {data:{isdelete:0,status:0}}
      getVipGradeList(req).then(res=>{
        if(res.code){
          if(res.code==1000){
              this.$refs['addVip'].baseList.lvList=res.data.list
              this.$refs['addVip'].show=true
          }
        }
      })
    },
    //添加成功调取列表
    addGetList(v){
      this.$refs['vue'].getList().then(()=>{
        this.$EClose()
      })
    },
    handleSelect(key, keyPath) {
      this.vue=key;
    },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: solid 1px #e6e6e6;
}
.el-menu {
  display: flex;
  align-items: flex-end;
}
.el-menu--horizontal > .el-menu-item {
  height: 45px;
}
.el-menu.el-menu--horizontal {
  padding-left: 86px !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid rgba(42, 85, 229, 1);
}
.main {
  width: 100%;
  height: calc(100% - 57px);
}
.el-dropdown-menu__item, .el-menu-item{
  padding: 0;
  margin: 0 20px !important;
}
  .otherDiv{
    padding: 0 20px;
    box-sizing: border-box;
  }
  .otherDiv>button{
    width: 123px;
    height: 39px;
    border-radius: 4px;
    background: rgba(73, 119, 252, 1);
    color: rgba(255, 255, 255, 1);
  }
  .otherDiv>button:hover{
    opacity: .9;
  }
</style>
