<template>
    <div>
        <el-dialog
                :title="detail?'商家详情':'关联商家'"
                custom-class="dialogClass"
                :visible.sync="show"
                width="30%">
            <el-form :model="form" :rules="rules"  ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <el-form-item label="商家账号" :prop="detail?'':'data.username'">
                    <el-input v-model="form.data.username" size="small" placeholder="请输入商家账号" maxlength="30" :readonly="detail"></el-input>
                </el-form-item>
              <el-form-item label="商家特约商户ID" :prop="detail?'':'data.submchid'">
                <el-input v-model="form.data.submchid" size="small" placeholder="请输入特约商户ID" maxlength="30" :readonly="detail"></el-input>
              </el-form-item>
              <el-form-item label="优米平台服务税率" :prop="detail?'':'data.servicetax'">
               <div style="display: flex;align-items: center">
                 <el-input v-model="form.data.servicetax" style="width: 80px" size="small"   placeholder="0.00" maxlength="8" :readonly="detail"></el-input>
                 <div style="margin-left: 6px">%</div>
               </div>
              </el-form-item>
                <el-form-item label="上传服务协议" v-if="detail">
                    <a :href="form.data.protocolurl" target="_blank" style="color: rgba(50, 135, 255, 1)" class="clickProtocol">
                      <img src="../../../../../assets/public/pdf.png" style="width: 67px;height: 67px" alt="">
                      查看协议链接
                    </a>
                </el-form-item>
                <el-form-item label="上传服务协议" prop="data.protocolurl" v-else class="mdyErr">
                    <EUpload ref="serviceProtocol" width="205px" height="116px" type="pdf">
                        <div style="color: rgba(237, 90, 56, 1);font-size: 12px;">上传PDF文件，大小为5M内</div>
                    </EUpload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!detail">
                <el-button @click="submitBtn">保存</el-button>
                <el-button type="primary" @click="resetForm">重置</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {addEUUserLi} from "@/api/workbenche";
    import rules from "@/util/js/formRules";
    export default {
        props:['detail'],
        data() {
            return {
                show: false,
                form:{
                    data:{
                        username:'',
                        serviceid:'',
                        protocolurl:'',
                        customerid:'',
                        servicename:'',
                      submchid:'',//特约商户ID
                      servicetax:'',//服务税率
                    }
                },
                rules:{
                    "data.username":rules(),
                    "data.protocolurl":rules(),
                    "data.submchid":rules('numAlphabet','',false),
                    "data.servicetax":rules('servicetax')
                },
            };
        },
        created() {
        },
        methods: {
          //打开链接
          openUrl(){
            window.open(this.form.data.protocolurl)
          },
          //提交表单
            submitBtn(){
              console.log(this.$parent.serviceItem)
                this.form.data.protocolurl=123123//this.$refs['serviceProtocol'].url
                this.form.data.serviceid=this.$parent.serviceItem.data1.id
                this.form.data.customerid=this.$parent.serviceItem.data1.customerid
                this.form.data.servicename=this.$parent.serviceItem.data1.companyname
                this.$refs['ruleForm'].validate(valid=>{
                    if(valid){
                        this.$ELoading()
                        const req = {
                            data:{
                                username:this.form.data.username,
                                protocolurl:this.form.data.protocolurl,
                                serviceid:this.form.data.serviceid,
                                customerid:this.form.data.customerid,
                                servicename:this.form.data.servicename,
                              submchid:this.form.data.submchid,
                              servicetax:this.form.data.servicetax
                            },
                        }
                        addEUUserLi(this.$req(req)).then(res=>{
                            if(res.code){
                                if(res.code==1000){
                                    this.$parent.addGetList()
                                    this.$message.success('操作成功')
                                    this.show=false
                                    this.resetForm()
                                }else{
                                    this.$message.error(res.message?res.message:'操作失败')
                                    this.$EClose()
                                }
                            }
                        })
                    }else{
                        return false
                    }
                })
            },
            resetForm(){
                this.$refs['ruleForm'].resetFields()
                this.$refs['serviceProtocol'].url=''
            },
        }
    };
</script>

<style scoped>
    ::v-deep .el-input__inner{
        max-width: 300px;
    }
    ::v-deep .dialogClass{
        width: auto !important;
        max-width:50vw;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
    }
    ::v-deep .el-dialog__title{
        font-size:15px;
        position: relative;
        padding-left: 15px;
        line-height: 1;
    }
    ::v-deep .el-dialog__title:before{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    ::v-deep .el-dialog__body{
        padding: 30px 40px;
        padding-bottom: 10px;
        max-height: calc(100vh - 25vh - 200px);
        overflow-y: auto;
    }
    .dialog-footer{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .dialog-footer>button:nth-of-type(1){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: rgba(31, 120, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .dialog-footer>button:hover{
        opacity: .9;
        transition: all linear .2s;
    }
    .dialog-footer>button:nth-of-type(2){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
        color: rgba(31, 120, 255, 1);
        font-size: 14px;
    }
    .formNode{
        margin-top: 5px;
        box-sizing: border-box;
    }
    .formNode_title{
        color: rgba(0, 0, 0, 1);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
    }
    .formNode_title:before{
        width: 5px;
        height: 15px;
        border-radius: 4px;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .clickProtocol{
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }
    .clickProtocol:hover{
        cursor: pointer;
        opacity: .8;
        text-decoration:underline;
        transition: all linear .1s;
    }
  ::v-deep  .mdyErr .el-form-item__error{
      margin-top: 15px;
    }
</style>
