<template>
    <div>
        <el-dialog
                title="创建账号"
                custom-class="dialogClass"
                :visible.sync="show"
                width="30%">
            <el-form :model="form" :rules="rules"  ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <el-form-item label="头像" prop="data1.imagehead">
                    <EUpload ref="headerUpload"></EUpload>
                </el-form-item>
                <el-form-item label="公司名称" prop="data1.companyname">
                    <el-input v-model="form.data1.companyname" size="small" placeholder="请输入公司名称" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="账号联系人" prop="data1.name">
                    <el-input v-model="form.data1.name" size="small" placeholder="请输入账号联系人" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="data1.cardno">
                    <el-input v-model="form.data1.cardno" size="small" placeholder="请输入身份证号" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="公司地址" prop="data1.registeraddress">
                    <el-input v-model="form.data1.registeraddress" size="small" placeholder="请输入公司地址" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="data1.userphone">
                    <el-input v-model="form.data1.userphone" size="small" placeholder="请输入联系电话" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="data1.phone">
                    <el-input v-model="form.data1.phone" size="small" placeholder="请输入手机号登录账号" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="所在城市" prop="data1.city">
                    <el-cascader
                            size="small"
                            ref="ref_address"
                            filterable
                            v-model="form.data1.city"
                            :options="baseList.ssq"
                            :props="{ expandTrigger: 'hover',value:'adcode',label:'title' }"
                            @change="cityChange"
                            placeholder="请选择所在城市"
                            separator="/"
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="渠道来源" prop="data1.fromadd">
                    <el-input v-model="form.data1.fromadd" size="small" placeholder="请输入渠道来源" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="上传营业执照" prop="data2.cardurl">
                    <EUpload ref="permitUpload" width="205px" height="116px"></EUpload>
                </el-form-item>
                <el-form-item label="上传身份证" required :show-message="false">
                    <div style="display: flex;">
                        <el-form-item label="" prop="data1.cardimg1">
                            <EUpload ref="cardFrontUpload" width="205px" height="116px" name="点击上传身份证正面"></EUpload>
                        </el-form-item>
                        <div style="padding: 10px"></div>
                        <el-form-item label="" prop="data1.cardimg2">
                            <EUpload ref="cardBackUpload" width="205px" height="116px" name="点击上传身份证反面"></EUpload>
                        </el-form-item>
                    </div>
                </el-form-item>
                <div class="formNode">
                    <div class="formNode_title">会员等级</div>
                    <el-form-item label="会员等级" prop="data1.level">
                        <el-select v-model="form.data1.level" filterable placeholder="请选择会员等级" size="small" @change="vipGradeChange">
                            <el-option :label="item.name"
                                       :value="{id:item.id,value:item.id,name:item.name,label:item.name,contracttotal:item.contracttotal,contractunit:item.contractunit,
                                       recharge1:item.recharge1,recharge2:item.recharge2,recharge3:item.recharge3,levelremark:item.remark}"
                                       v-for="item in baseList.lvList" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="name">
                        <div style="height: 250px">
                            <ETable :tableData="tableData" ref="ETable" :cellClass="cellClass">
                                <template #day="{rowData:rowData}">
                                    {{rowData.day?effectiveShow(rowData.day):'--'}}
                                </template>
                                <template #money="{rowData:rowData}">
                                    {{rowData.money?rowData.money+'元':'--'}}
                                </template>
                            </ETable>
                        </div>
                    </el-form-item>
                    <el-form-item label="合同单价">
                        <div style="width:100%;display:flex;align-items:center">
                            <div style="width: 30%">
                                <el-input v-model="form.data1.contractunit" size="small" placeholder="合同单价" readonly maxlength="30"></el-input>
                            </div>
                            <div style="margin-left: 5px">元/份</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="套餐赠送合同量">
                        <div style="width:100%;display:flex;align-items:center">
                            <div style="width: 30%">
                                <el-input v-model="form.data1.contracttotal" size="small" placeholder="套餐赠送合同量" readonly maxlength="30"></el-input>
                            </div>
                            <div style="margin-left: 5px">份</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="合同充值量" prop="data1.contracttotal2">
                        <div style="width:100%;display:flex;align-items:center">
                            <div style="width: 30%">
                                <el-input v-model="form.data1.contracttotal2" size="small" placeholder="请输入合同充值量" maxlength="30"></el-input>
                            </div>
                            <div style="margin-left: 5px">份</div>
                        </div>
                    </el-form-item>
                </div>
                <div class="formNode">
                    <div class="formNode_title">开通微工卡</div>
                    <el-form-item label="特约商户ID" prop="data3.submchid">
                        <el-input v-model="form.data3.submchid" size="small" placeholder="请输入特约商户ID" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="收货地址" prop="data1.address">
                        <el-input v-model="form.data1.address" size="small" placeholder="请输入收货地址" maxlength="30"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submitBtn">立即创建</el-button>
                <el-button type="primary" @click="resetForm">重置</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {addVipAccLi} from "../../../../../api/workbenche";
    import rules from "../../../../../util/js/formRules";
    export default {
        data() {
            return {
                show: false,
                tableData:{
                    data:[],
                    cols:[
                        {label:'有效期',prop:'day'},
                        {label:'价格',prop:'money'},
                    ],
                    paging:0,
                    operate:0,
                    selection:1,
                    selectionRadio:true,
                    serial:0,
                },
                baseList:{
                    ssq:[],
                    lvList:[],//会员等级
                },
                effectiveBaseList:[
                  {label:'一个月（30天）',value:"30"},
                  {label:'一季度（90天）',value:"90"},
                  {label:'半年度（180天）',value:"180"},
                  {label:'一年度（365天）',value:"365"},
                  {label:'两年度（730天）',value:"730"},
                  {label:'三年度（1095天）',value:"1095"},
                ],
                form:{
                    data1:{
                        name:'',
                        phone:'',//登录账号
                        companyname:'',
                        registeraddress:'',
                        imagehead:'',
                        address:'',
                        level:'',//等级
                        userlevelid:'',
                        levelname:'',
                        levelremark:'',
                        contracttotal:'',//contracttotal2+contracttotal
                        contracttotal2:'',//contracttotal2+contracttotal
                        contractunit:'',
                        cardno:'',
                        cardimg1:'',
                        cardimg2:'',
                        fromadd:'',
                        userphone:'',//联系人手机号
                        adcode:'',
                        city:'',//城市
                        submchid:'',
                        levelrecharge:'',//json 规则
                    },
                    data2:{
                        companyname:'',
                        cardurl:'',
                    },
                    data3:{
                        submchid:''
                    },
                },
                rules:{
                    "data1.name":rules(),
                    "data1.imagehead":rules(),
                    "data1.companyname":rules(),
                    "data1.cardno":rules('card'),
                    "data1.registeraddress":rules(),
                    "data1.phone":rules('phone'),
                    "data1.userphone":rules('phone'),
                    "data1.adcode":rules(),
                    "data1.city":rules(),
                    "data1.fromadd":rules(),
                    "data2.cardurl":rules(),
                    "data1.cardimg1":rules(),
                    "data1.cardimg2":rules(),
                    "data1.level":rules(),
                    "data1.levelname":rules(),
                    "data1.contractunit":rules(),
                    "data1.contracttotal":rules(),
                    "data1.contracttotal2":rules('moneyInt','',false),
                    "data3.submchid":rules(),
                    "data1.address":rules(),
                },
            };
        },
        created() {
          const ssq = JSON.parse(JSON.stringify(this.$store.state.regionData))
          ssq.shift()
          this.baseList.ssq=ssq
        },
        methods: {
          //有效期回显
          effectiveShow(value){
            for (let key of this.effectiveBaseList) {
              if(value==key.value){
                return key.label
              }
            }
            return '--'
          },
            //城市数据处理
            cityChange(v){
                this.form.data1.adcode=v[2]
            },
            //会员等级数据处理
            vipGradeChange(v){
                const {id,name,contracttotal,contractunit,recharge1,recharge2,recharge3,levelremark} = v
                this.form.data1.contractunit=contractunit
                this.form.data1.contracttotal=contracttotal
                this.form.data1.levelname=name
                this.form.data1.userlevelid=id
                this.form.data1.levelremark=levelremark
                const getRechargeList = [JSON.parse(recharge1),JSON.parse(recharge2),JSON.parse(recharge3)]
                this.tableData.data=getRechargeList.filter(item=>item.status===1)
            },
            submitBtn(){
                this.form.data1.imagehead=this.$refs['headerUpload'].url
                this.form.data1.cardimg1=this.$refs['cardFrontUpload'].url
                this.form.data1.cardimg2=this.$refs['cardBackUpload'].url
                this.form.data2.cardurl=this.$refs['permitUpload'].url
                this.$refs['ruleForm'].validate(valid=>{
                    if(valid){
                        this.$ELoading()
                        const req = {
                            data1:{
                                name:this.form.data1.name,
                                phone:this.form.data1.phone,
                                companyname:this.form.data1.companyname,
                                registeraddress:this.form.data1.registeraddress,
                                imagehead:this.form.data1.imagehead,
                                address:this.form.data1.address,
                                userlevelid:this.form.data1.userlevelid,
                                levelname:this.form.data1.levelname,
                                levelremark:this.form.data1.levelremark,
                                contracttotal:parseInt(Number(this.form.data1.contracttotal)+Number(this.form.data1.contracttotal2)),
                                contractunit:this.form.data1.contractunit,
                                cardno:this.form.data1.cardno,
                                cardimg1:this.form.data1.cardimg1,
                                cardimg2:this.form.data1.cardimg2,
                                fromadd:this.form.data1.fromadd,
                                userphone:this.form.data1.userphone,
                                adcode:this.form.data1.adcode,
                                submchid :this.form.data3.submchid,
                                levelrecharge:String(this.$refs['ETable']?.multipleSelection?.map(item=>JSON.stringify(item)))
                            },
                            data2:{
                                companyname:this.form.data1.companyname,
                                cardurl:this.form.data2.cardurl,
                            },
                            data3:{
                                submchid :this.form.data3.submchid,
                            }
                        }
                        addVipAccLi(this.$req(req)).then(res=>{
                            if(res.code){
                                if(res.code==1000){
                                    this.$parent.addGetList()
                                    this.$message.success('操作成功')
                                    this.show=false
                                    this.resetForm()
                                }else{
                                    this.$message.error(res.message?res.message:'操作失败')
                                    this.$EClose()
                                }
                            }
                        })
                    }else{
                        return false
                    }
                })
            },
            resetForm(){
                this.$refs['ruleForm'].resetFields()
                this.$refs['headerUpload'].url=''
                this.$refs['permitUpload'].url=''
                this.$refs['cardFrontUpload'].url=''
                this.$refs['cardBackUpload'].url=''
                this.tableData.data=[]
            },
            // 隐藏表头中的全选框
            cellClass(row) {
                if (row.columnIndex === 0) {
                    return 'disabledCheck'
                }
            },
        }
    };
</script>

<style scoped>
    ::v-deep .el-input__inner{
        max-width: 300px;
    }
    ::v-deep .dialogClass{
        width: auto !important;
        max-width:50vw;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
    }
    ::v-deep .el-dialog__title{
        font-size:15px;
        position: relative;
        padding-left: 15px;
        line-height: 1;
    }
    ::v-deep .el-dialog__title:before{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    ::v-deep .el-dialog__body{
        padding: 30px 40px;
        padding-bottom: 10px;
        max-height: calc(100vh - 25vh - 200px);
        overflow-y: auto;
    }
    .dialog-footer{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .dialog-footer>button:nth-of-type(1){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: rgba(31, 120, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .dialog-footer>button:hover{
        opacity: .9;
        transition: all linear .2s;
    }
    .dialog-footer>button:nth-of-type(2){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
        color: rgba(31, 120, 255, 1);
        font-size: 14px;
    }
    .formNode{
        margin-top: 5px;
        box-sizing: border-box;
    }
    .formNode_title{
        color: rgba(0, 0, 0, 1);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
    }
    .formNode_title:before{
        width: 5px;
        height: 15px;
        border-radius: 4px;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
</style>
