<template>
  <div class="pageMain">
    <div class="tableSearch">
      <div>会员列表</div>
      <div class="search_form">
        <el-form :model="listReq.data" :rules="rules"  ref="ruleForm" label-width="auto" class="demo-ruleForm fromLabels">
          <el-form-item label="状态" prop="status">
            <div class="label">
              <el-select v-model="listReq.data.status" placeholder="请选择状态" size="small">
                <el-option
                        v-for="item in list.stateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <div class="label">
              <el-input v-model="listReq.data.name" size="small" placeholder="请输入姓名" clearable></el-input>
            </div>
          </el-form-item>
          <el-form-item label="手机号" prop="userphone">
            <div class="label">
              <el-input v-model="listReq.data.userphone" size="small" placeholder="请输入手机号" clearable></el-input>
            </div>
          </el-form-item>
          <el-form-item label="注册时间" prop="startline">
            <div class="label">
              <el-date-picker
                      style="max-width: 250px"
                      v-model="listReq.data.startline"
                      type="daterange"
                      size="small"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="注册时间"
                      end-placeholder="注册时间">
              </el-date-picker>
            </div>
          </el-form-item>


          </el-form>
        <div class="btnLabel">
          <button @click="searchEndBtn">
            <img src="../../../../assets/button/搜索icon.png" alt=""><div style="margin-left: 8px">搜索</div>
          </button>
          <button @click="searchEndReset">
            <img src="../../../../assets/button/重置icon.png" alt=""><div style="margin-left: 8px">重置</div>
          </button>
        </div>
      </div>
    </div>
    <div class="tableMain">
      <div class="tableBox">
          <ETable ref="ETable" :tableData="tableData"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            <template #phone="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.phone?rowData.data1.phone:'--'}}
            </template>
            <template #name="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.name?rowData.data1.name:'--'}}
            </template>
            <template #userphone="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.userphone?rowData.data1.userphone:'--'}}
            </template>
            <template #levelname="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.levelname?rowData.data1.levelname:'--'}}
            </template>
            <template #contracttotal="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.contracttotal?rowData.data1.contracttotal+'份':'--'}}
            </template>
            <template #et="{rowData:rowData}">
              {{rowData.data2&&rowData.data2.et?rowData.data2.et+'份':'--'}}
            </template>
            <template #endline="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.endline?$iApi.moment(rowData.data1.endline,'YYYY-MM-DD'):'--'}}
            </template>
            <template #startline="{rowData:rowData}">
              {{rowData.data1&&rowData.data1.startline?$iApi.moment(rowData.data1.startline,'YYYY-MM-DD'):'--'}}
            </template>
            <template #operate="{rowData:rowData}">
              <el-button type="text" size="small" @click="descVip(rowData)">详情</el-button>
              <el-button type="text" size="small" @click="alterVip(rowData)">修改</el-button>
              <el-button type="text" size="small" @click="recharge(rowData)">充值</el-button>
              <el-button type="text" size="small" @click="renewVip(rowData)">续费</el-button>
              <template>
                <el-button type="text" size="small" v-if="rowData.data1.auth==1">已认证</el-button>
                <el-button type="text" size="small" @click="openFDD(rowData)" v-else>开通法大大</el-button>
              </template>
              <template>
                <el-button type="text" size="small" @click="alterVipStatus(rowData,0)" v-if="rowData.data1&&rowData.data1.status==1">停用</el-button>
                <el-button type="text" size="small" @click="alterVipStatus(rowData,1)" v-else>启用</el-button>
              </template>
            </template>
          </ETable>
      </div>
    </div>
    <recharge ref="recharge"></recharge>
    <alterVip ref="alterVip"></alterVip>
    <renewVip ref="renewVip"></renewVip>
    <descVip ref="descVip" :readonlyFlag="readonlyFlag"></descVip>
  </div>
</template>

<script>
  import {getVipAccList, getVipGradeList,alterVipAccEd} from "@/api/workbenche";
  import {pcToAuthenticate} from "@/api/public";
  import rules from "../../../../util/js/formRules";
  export default {
    name: "end",
    components:{
      recharge:()=>import('./component/recharge'),
      alterVip:()=>import('./component/alterVip'),
      renewVip:()=>import('./component/renewVip'),
      descVip:()=>import('./component/alterVip')
    },
    data() {
      return {
        //表格列表请求体
        listReq:{
          index:20,
          page:1,
          data:{
            name:'',
            userphone:'',
            status:1,
            serviceauth:0,
            startline:[]
          }
        },
        rules:{
          name:rules('','',false),
          userphone:rules('','',false),
          status:rules('','',false),
          startline:rules('','',false),
        },
        //过滤数据
        list:{
          stateList:[
            {label:'停用',value:0},
            {label:'启用',value:1},
            // {label:'待审核',value:2}
          ]
        },
        tableData: {
          currentPage:0,
          data: [],
          cols:[
            {label:'用户名',prop:'phone'},
            {label:'姓名',prop:'name'},
            {label:'手机号',prop:'userphone'},
            {label:'会员级别',prop:'levelname'},
            {label:'合同总量',prop:'contracttotal'},
            {label:'合同余量',prop:'et'},
            {label:'有效期',prop:'endline'},
            {label:'注册时间',prop:'startline'},
          ],
        },
        //详情、修改控制
        readonlyFlag:false,
      };
    },
    created() {
      this.$ELoading()
      this.getList().then(()=>{
        this.$EClose()
      })
    },
    methods:{
      //获取列表
      getList(){
        return new Promise(resolve => {
          getVipAccList(this.$req(this.listReq)).then(res=>{
            if(res.code){
              resolve()
              if(res.code==1000){
                this.tableData.data=res.data
              }else{
                this.$message.error(res.message?res.message:'操作失败')
              }
            }
          })
        })
      },
      //充值
      recharge(item){
        this.$refs['recharge'].form.id=item.data2.id
        this.$refs['recharge'].form.residue=item.data2.et
        this.$refs['recharge'].form.contractunit=item.data1.contractunit
        this.$refs['recharge'].show=true
        this.$nextTick(()=>{
          this.$refs['recharge'].resetForm()
        })
      },
      //续费
      renewVip(item){
        this.$ELoading()
        this.$refs['renewVip'].form.data.id=item.data1.id
        const req = {data:{isdelete:0}}
        getVipGradeList(req).then(res=>{
          if(res.code){
            this.$EClose()
            if(res.code==1000){
              this.$refs['renewVip'].baseList.lvList=res.data.list
              this.$refs['renewVip'].show=true
              //获取会员等级
              const level = res?.data?.list?.filter(itemX=>itemX.name==item?.data1?.levelname).map(itemY=>{
                return {id:itemY.id,value:itemY.id,name:itemY.name,label:itemY.name,contracttotal:itemY.contracttotal,contractunit:itemY.contractunit,
                  recharge1:itemY.recharge1,recharge2:itemY.recharge2,recharge3:itemY.recharge3,levelremark:itemY.remark}
              })[0]
              this.$refs['renewVip'].form.data.level=level
              //回显等级套餐
              const getRechargeList =level&&(level.recharge1||level.recharge2||level.recharge3)?[JSON.parse(level?.recharge1),JSON.parse(level?.recharge2),JSON.parse(level?.recharge3)]:[]
              this.$refs['renewVip'].tableData.data=getRechargeList.filter(item=>item.status===1)
              this.$refs['renewVip'].form.data.userlevelid=level?.id
              this.$refs['renewVip'].form.data.contracttotal=level?.contracttotal
              this.$refs['renewVip'].form.data.contractunit=level?.contractunit
              this.$refs['renewVip'].form.data.levelname=level?.name
              this.$refs['renewVip'].form.data.levelremark=level?.levelremark
            }else{
              this.$message.error(res.message?res.message:'操作失败')
            }
          }
        })
        this.$refs['renewVip'].show=true
      },
      //开通法大大
      openFDD(item){
        if(item.data1.fadadaurl){
          window.open(item.data1.fadadaurl)
        }else{
          this.$ELoading()
          pcToAuthenticate(item.data1.id).then(res=>{
            if(res.code){
              if(res.code==1000){
                this.getList().then(()=>{
                  this.$EClose()
                })
                window.open(res.data.data.url)
              }else{
                this.$message.error('操作失败')
                this.$EClose()
              }
            }
          })
        }
      },
      //详情
      descVip(item){
        this.readonlyFlag=true
        this.alterVip(item,'descVip')
      },
      //修改
      alterVip(item,name='alterVip'){
        //详情流程
        if(name==='descVip'){
          this.$ELoading()
          const req = {data:{isdelete:0}}
          getVipGradeList(req).then(res=>{
            this.$refs[name].show=true
            this.$nextTick(()=>{
              this.$refs[name].form.data1.id=item?.data1?.id
              this.$refs[name].form.data2.id=item?.data3?.id
              this.$refs[name].$refs['headerUpload'].url=item?.data1?.imagehead
              this.$refs[name].$refs['permitUpload'].url=item?.data3?.cardurl
              this.$refs[name].$refs['cardFrontUpload'].url=item?.data1?.cardimg1
              this.$refs[name].$refs['cardBackUpload'].url=item?.data1?.cardimg2
              this.$refs[name].form.data1.imagehead=item?.data1?.imagehead
              this.$refs[name].form.data2.cardurl=item?.data3?.cardurl
              this.$refs[name].form.data1.cardimg1=item?.data1?.cardimg1
              this.$refs[name].form.data1.cardimg2=item?.data1?.cardimg2
              this.$refs[name].form.data1.city=item?.data1?.adcode?this.$iApi.dictPropArea(item?.data1?.adcode).join('/')||'':''
              this.$refs[name].form.data1.adcode=item?.data1?.adcode
              this.$refs[name].form.data1.companyname=item?.data1?.companyname
              this.$refs[name].form.data1.registeraddress=item?.data1?.registeraddress
              this.$refs[name].form.data1.name=item?.data1?.name
              this.$refs[name].form.data1.phone=item?.data1?.phone
              this.$refs[name].form.data1.cardno=item?.data1?.cardno
              this.$refs[name].form.data1.userphone=item?.data1?.userphone
              this.$refs[name].form.data1.fromadd=item?.data1?.fromadd
              //与else相比【
              this.$refs[name].form.data3.submchid=item?.data1?.submchid
              this.$refs[name].form.data1.address=item?.data1?.address
              this.$refs[name].tableData.selection=0
              //与else相比】
              this.$nextTick(()=>{
                this.$refs[name].$refs['ruleForm'].clearValidate()
              })
            })
            if(res.code){
              this.$EClose()
              if(res.code==1000){
                this.$refs[name].baseList.lvList=res.data.list
                //获取会员等级
                const level = res?.data?.list?.filter(itemX=>itemX.name==item?.data1?.levelname).map(itemY=>{
                  return {id:itemY.id,value:itemY.id,name:itemY.name,label:itemY.name,contracttotal:itemY.contracttotal,contractunit:itemY.contractunit,
                    recharge1:itemY.recharge1,recharge2:itemY.recharge2,recharge3:itemY.recharge3,levelremark:itemY.remark}
                })[0]
                this.$refs[name].form.data1.level=level
                //回显等级套餐
                const getRechargeList = [JSON.parse(level.recharge1),JSON.parse(level.recharge2),JSON.parse(level.recharge3)]
                // this.$refs[name].tableData.data=getRechargeList.filter(item=>item.status===1)
                const clearEm = [JSON.parse(item.data1.levelrecharge)]?.filter(item=>item)
                this.$refs[name].tableData.data=clearEm
                this.$refs[name].form.data1.contracttotal=level.contracttotal
                this.$refs[name].form.data1.contracttotal2=String(parseInt(item.data2.et-level.contracttotal))
                this.$refs[name].form.data1.contractunit=level.contractunit
                this.$refs[name].form.data1.levelname=level.name
                this.$refs[name].form.data1.levelremark=level.levelremark
              }
            }
          })
        }else{
          this.$refs[name].show=true
          this.readonlyFlag=false
          this.$nextTick(()=>{
            this.$refs[name].form.data1.id=item?.data1?.id
            this.$refs[name].form.data2.id=item?.data3?.id
            this.$refs[name].$refs['headerUpload'].url=item?.data1?.imagehead
            this.$refs[name].$refs['permitUpload'].url=item?.data3?.cardurl
            this.$refs[name].$refs['cardFrontUpload'].url=item?.data1?.cardimg1
            this.$refs[name].$refs['cardBackUpload'].url=item?.data1?.cardimg2
            this.$refs[name].form.data1.imagehead=item?.data1?.imagehead
            this.$refs[name].form.data2.cardurl=item?.data3?.cardurl
            this.$refs[name].form.data1.cardimg1=item?.data1?.cardimg1
            this.$refs[name].form.data1.cardimg2=item?.data1?.cardimg2
            this.$refs[name].form.data1.city=item?.data1?.adcode?this.$iApi.dictPropArea(item?.data1?.adcode,'adcode')||[]:[]
            this.$refs[name].form.data1.adcode=item?.data1?.adcode
            this.$refs[name].form.data1.companyname=item?.data1?.companyname
            this.$refs[name].form.data1.registeraddress=item?.data1?.registeraddress
            this.$refs[name].form.data1.name=item?.data1?.name
            this.$refs[name].form.data1.phone=item?.data1?.phone
            this.$refs[name].form.data1.cardno=item?.data1?.cardno
            this.$refs[name].form.data1.userphone=item?.data1?.userphone
            this.$refs[name].form.data1.fromadd=item?.data1?.fromadd
            this.$refs[name].form.data3.submchid=item?.data1?.submchid
            this.$nextTick(()=>{
              this.$refs[name].$refs['ruleForm'].clearValidate()
            })
          })
        }
      },
      //停用/启用
      alterVipStatus(item,status){
        if(!status){
          this.$EConfirm('此操作将停用该会员账号，该会员及该会员创建的其他帐号将无法登录，是否继续？','停用会员账号','warning').then(()=>{
            this.$ELoading()
            alterVipAccEd(item.data1.id,status).then(res=>{
              if(res.code){
                if(res.code==1000){
                  this.getList().then(()=>{
                    this.$EClose()
                  })
                  this.$message.success('操作成功')
                }else{
                  this.$EClose()
                  this.$message.error(res.message?res.message:'操作失败')
                }
              }
            })
          }).catch(()=>{})
        }else{
          this.$ELoading()
          alterVipAccEd(item.data1.id,status).then(res=>{
            if(res.code){
              if(res.code==1000){
                this.getList().then(()=>{
                  this.$EClose()
                })
                this.$message.success('操作成功')
              }else{
                this.$EClose()
                this.$message.error(res.message?res.message:'操作失败')
              }
            }
          })
        }
      },
      //搜索
      searchEndBtn(){
        this.$ELoading()
        this.getList().then(()=>{
          this.$EClose()
        })
      },
      //重置
      searchEndReset(){
        this.$refs['ruleForm'].resetFields()
        this.$ELoading()
        this.getList().then(()=>{
          this.$EClose()
        })
      },
      handleSizeChange(val) {
        this.listReq.index=val
        this.listReq.page=1
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
      handleCurrentChange(val) {
        this.listReq.page=val
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
      //表单
      submitForm(formName){
        this.$refs[formName].validate((valid)=>{
          if (valid) {
          } else {
            return false
          }
        })
      },
      //表单重置
      resetForm(formName){
        this.$refs[formName].resetFields()
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableSearch {
    width: 100%;
    padding: 15px 26px 15px 35px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    font-size: 14px;
    height: 154px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
  .search_form{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    flex: 1;
  }
  .fromLabels {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  ::v-deep .fromLabels .el-form-item{
    margin-bottom: 0;
  }
  ::v-deep .el-input__inner {
    height: 31px;
    line-height: 31px;
  }
  ::v-deep  .el-select .el-input .el-select__caret {
    line-height: 31px;
  }
  ::v-deep  .el-select .el-input .el-select__caret {
    color: rgba(100, 102, 107, 1);
  }
  .label {
    position: relative;
    margin-right: 10px;
  }
  .btnLabel {
    width: 200px;
    height: 35px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 36px;
  }
  .btnLabel > button:hover {
    cursor: pointer;
  }
  .btnLabel>button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }
  .btnLabel img{
    width: 13px;
  }
  .btnLabel>button:nth-of-type(1){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border: 1px solid  rgba(73, 119, 252, 1);
  }
  .btnLabel>button:nth-of-type(2){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(73, 119, 252, 1);
  }
  .tableMain {
    width: 100%;
    height: calc(100% - 154px);
    background: #f5f6fa;
    box-sizing: border-box;
  }
  .tableBox {
    width: 100%;
    height: 100%;
    background: white;
  }
  ::v-deep .el-table tr {
    background: white;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  ::v-deep  .el-table .sort-caret.ascending {
    border-bottom-color: #409eff;
    top: 5px;
  }
  ::v-deep  .el-table .sort-caret.descending {
    border-top-color: #409eff;
    bottom: 7px;
  }
  ::v-deep  .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #c0c4cc;
  }
  ::v-deep  .el-table .descending .sort-caret.descending {
    border-top-color: #c0c4cc;
  }
  /*分页*/
  .paging{
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep  .paging .el-input__inner{
    padding: 0 15px !important;
  }
  ::v-deep  .scroll .el-table .el-table__cell{
    padding: 4px 0 !important;
  }
  /*表格最后一列小白块处理*/
  ::v-deep  .el-table th.el-table__cell{
    background: rgb(245, 246, 250);
  }
</style>
