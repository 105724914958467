<template>
  <div class="pageMain">
    <div class="tableSearch">
      <div>会员列表</div>
      <div class="search_form">
        <div class="fromLabels">
          <div class="label">
            <el-select v-model="tableReq.data.state" placeholder="请选择状态" size="small">
              <el-option
                      v-for="item in []"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="label">
            <el-input v-model="tableReq.data.phone" size="small" placeholder="请输入姓名" clearable></el-input>
          </div>
          <div class="label">
            <el-input v-model="tableReq.data.phone" size="small" placeholder="请输入手机号" clearable></el-input>
          </div>
          <div class="label">
            <el-input v-model="tableReq.data.phone" size="small" placeholder="请输入企服经理" clearable></el-input>
          </div>
          <div class="label">
            <el-date-picker
                    style="max-width: 250px"
                    v-model="tableReq.data.time"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="注册时间"
                    end-placeholder="注册时间">
            </el-date-picker>
          </div>
        </div>
        <div class="btnLabel">
          <button @click="searchEndBtn">
            <img src="../../../../assets/button/搜索icon.png" alt=""><div style="margin-left: 8px">搜索</div>
          </button>
          <button @click="searchEndReset">
            <img src="../../../../assets/button/重置icon.png" alt=""><div style="margin-left: 8px">重置</div>
          </button>
        </div>
      </div>
    </div>
    <div class="tableMain">
      <div class="tableBox">
        <div class="scroll">
          <template>
            <el-table
                    :data="tableData.data"
                    style="width: 100%"
                    :header-cell-style="{ background: '#F5F6FA', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }"
                    empty-text="暂无数据"
                    element-loading-text="拼命加载中"
                    height="100%"
            >
              <el-table-column prop="" label="序号" sortable width="100">
                <template slot-scope="scope">
                  {{scope.$index+1+(tableReq.index)*(tableReq.page-1)}}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="用户名" sortable>
              </el-table-column>
              <el-table-column prop="name" label="姓名" sortable>
              </el-table-column>
              <el-table-column prop="name" label="手机号" sortable>
              </el-table-column>
              <el-table-column prop="phone" label="会员级别" sortable>
              </el-table-column>
              <el-table-column prop="phone" label="合同总量" sortable>
              </el-table-column>
              <el-table-column prop="phone" label="合同余量" sortable>
              </el-table-column>
              <el-table-column prop="companyname" label="有效期" sortable>
              </el-table-column>
              <el-table-column prop="companyname" label="注册时间" sortable>
              </el-table-column>
              <el-table-column prop="operate" label="操作" width="160">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="scope.row">详情</el-button>
                  <el-button type="text" size="small" @click="scope.row">修改</el-button>
                  <el-button type="text" size="small" @click="scope.row">充值</el-button>
                  <el-button type="text" size="small" @click="scope.row">停用</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div class="paging">
          <el-pagination
                  background
                  @size-change="changePageSize"
                  @current-change="changePageNo"
                  :current-page="tableReq.page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="tableReq.index"
                  layout="total, prev, pager, next, sizes"
                  :total="tableData.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "end",
    data() {
      return {
        tableReq:{
          "data":{
          },
          "page" : 1,
          "index":20,
        },
        tableData: [],
      };
    },
    created() {
    },
    methods:{
      //获取列表数据
      getTableData(){
        this.$ELoading()
        this.$EClose()
      },
      //搜索
      searchEndBtn(){
        this.$ELoading()
        this.getTableData()
      },
      //重置
      searchEndReset(){
        this.$ELoading()
        this.getTableData()
      },
      //页条数量
      changePageSize(v){
        this.$ELoading()
        this.tableReq.index=v
        this.tableReq.page=1
        this.getTableData()
      },
      //页码数量
      changePageNo(v){
        this.$ELoading()
        this.tableReq.page=v
        this.getTableData()
      },
      //表单
      submitForm(formName){
        this.$refs[formName].validate((valid)=>{
          if (valid) {
          } else {
            return false
          }
        })
      },
      //表单重置
      resetForm(formName){
        this.$refs[formName].resetFields()
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableSearch {
    width: 100%;
    padding: 15px 26px 15px 35px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    font-size: 14px;
    min-height: 154px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .search_form{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    flex: 1;
  }
  .fromLabels {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  ::v-deep .el-input__inner {
    height: 31px;
    line-height: 31px;
  }
  ::v-deep  .el-select .el-input .el-select__caret {
    line-height: 31px;
  }
  ::v-deep  .el-select .el-input .el-select__caret {
    color: rgba(100, 102, 107, 1);
  }
  .label {
    position: relative;
    margin-right: 10px;
  }
  .btnLabel {
    width: 200px;
    height: 35px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 36px;
  }
  .btnLabel > button:hover {
    cursor: pointer;
  }
  .btnLabel>button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }
  .btnLabel img{
    width: 13px;
  }
  .btnLabel>button:nth-of-type(1){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border: 1px solid  rgba(73, 119, 252, 1);
  }
  .btnLabel>button:nth-of-type(2){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(73, 119, 252, 1);
  }
  .tableMain {
    width: 100%;
    height: calc(100% - 154px);
    background: #f5f6fa;
    box-sizing: border-box;
  }
  .tableBox {
    width: 100%;
    height: 100%;
    background: white;
  }
  ::v-deep .el-table tr {
    background: white;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  ::v-deep  .el-table .sort-caret.ascending {
    border-bottom-color: #409eff;
    top: 5px;
  }
  ::v-deep  .el-table .sort-caret.descending {
    border-top-color: #409eff;
    bottom: 7px;
  }
  ::v-deep  .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #c0c4cc;
  }
  ::v-deep  .el-table .descending .sort-caret.descending {
    border-top-color: #c0c4cc;
  }
  /*分页*/
  .paging{
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep  .paging .el-input__inner{
    padding: 0 15px !important;
  }
  ::v-deep  .scroll .el-table .el-table__cell{
    padding: 4px 0 !important;
  }
  /*表格最后一列小白块处理*/
  ::v-deep  .el-table th.el-table__cell{
    background: rgb(245, 246, 250);
  }
</style>
