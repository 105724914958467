<template>
  <div class="page">
    <div class="header">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <span class="returnClass" @click="$parent.storeIf=false">返回</span>
        <el-menu-item :index="activeIndex">商家列表</el-menu-item>
      </el-menu>
      <div class="otherDiv">
        <el-button @click="addSvs">关联商家</el-button>
      </div>
    </div>
    <div class="main">
      <component :is="vue" ref="vue" :serviceItem="serviceItem"></component>
    </div>
    <addSvs ref="addSvs"></addSvs>
  </div>
</template>

<script>
export default {
  props:['serviceItem'],
  data() {
    return {
      activeIndex: "default",
      vue:'default',
    };
  },
  components:{
    default:()=>import('./account/default'),
    addSvs:()=>import('./account/component/addSvs')
  },
  methods: {
    //添加等级
    addSvs(){
      this.$refs['addSvs'].show=true
    },
    //添加成功调取列表
    addGetList(v){
      this.$refs['vue'].getList().then(()=>{
        this.$EClose()
      })
    },
    handleSelect(key, keyPath) {
      this.vue=key;
    },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: solid 1px #e6e6e6;
}
.el-menu {
  display: flex;
  align-items: flex-end;
}
.el-menu--horizontal > .el-menu-item {
  height: 45px;
}
.el-menu.el-menu--horizontal {
  padding-left: 86px !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid rgba(42, 85, 229, 1);
}
.main {
  width: 100%;
  height: calc(100% - 57px);
}
.el-dropdown-menu__item, .el-menu-item{
  padding: 0;
  margin: 0 20px !important;
}
.otherDiv{
  padding: 0 20px;
  box-sizing: border-box;
}
.otherDiv>button{
  width: 123px;
  height: 39px;
  border-radius: 4px;
  background: rgba(73, 119, 252, 1);
  color: rgba(255, 255, 255, 1);
}
.otherDiv>button:hover{
  opacity: .9;
}
.returnClass{
  position: absolute;
  top: 20px;
  left: 20px;
  color: gray;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.returnClass:hover{
  color: black;
  cursor: pointer;
}
</style>
