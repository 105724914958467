<template>
  <div class="hello">
    <div class="left">
<!--      <img src="../assets/head/logo.png" alt="" width="42px" height="42px" @click="$router.push('/')">-->
      <span @click="$router.push('/')">PaaS</span>
    </div>
    <el-menu :default-active="msg" class="el-menu-demo" mode="horizontal" @select="handleSelect" active-text-color="#2A55E5" text-color="#595D5C" router>
      <el-menu-item :index="item.path" v-for="(item,i) in allRouter" :key="i">{{item.name}}</el-menu-item>
    </el-menu>
    <div class="right">
      <template v-if="userInfo">
        <img  :src="userInfo.imagehead?userInfo.imagehead:'https://testfile.liyurencai.com/images/20230807/40e66a37-1606-4d6e-a739-eab1c578b026.png'"  class="tuImg"  @click="info()"/>
        <span class="userName">{{userInfo.name?userInfo.name:userInfo.username}}</span>
      </template>
      <template v-else>
        <img style="padding: 0 15px; margin-left: 43px" :src="''"  class="tuImg"/>
        <span class="userName">无数据</span>
      </template>
      <div style="margin-left: 10px;">
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link">
            <img src="../assets/头像旁下拉.png"  alt="" class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  command="info">
              <div class="dropdown-li">
                <span><img src="../assets/head/修改密码.png" alt=""></span><span>个人信息</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item  command="revise">
              <div class="dropdown-li">
                <span><img src="../assets/head/修改密码.png" alt=""></span><span>修改密码</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item  command="quit">
              <div class="dropdown-li">
                <span><img src="../assets/head/退出.png" alt=""></span><span>退出登陆</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!--     头   账户信息弹出窗-->
    <div class="popup">
      <el-dialog title="个人信息" :visible.sync="infoDialogVisible">
        <div class="scroll" ref="scroll">
          <el-form v-if="overInfoReq" :model="overInfoReq" :rules="formRule" :inline-message="true" ref="infoForm">
            <div style="text-align: center;margin-bottom: 10px;" class="pictureImg">
              <template>
                <el-upload
                        class="avatar-uploader"
                        action=""
                        :http-request="uploadHead"
                        :show-file-list="false"
                        :before-upload="beforeAvatarUpload">
                  <img v-if="uploadImagehead" :src="uploadImagehead" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </template>
            </div>
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
              <div style="width: 70%">
                <el-input v-model="overInfoReq.name" autocomplete="off" maxlength="5" placeholder="请输入姓名"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="手机号"  :label-width="formLabelWidth" prop="phone">
              <div style="width: 70%">
                <el-input v-model="overInfoReq.phone" autocomplete="off" maxlength="11" placeholder="请输入手机号"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="性别"  :label-width="formLabelWidth" required>
              <div style="width: 70%" class="sexRadio">
                <el-radio v-model="overInfoReq.sex" label="1">男</el-radio>
                <el-radio v-model="overInfoReq.sex" label="2">女</el-radio>
              </div>
            </el-form-item>
            <el-form-item label="身份描述"  :label-width="formLabelWidth">
              <div style="width: 70%">
                <el-input v-model="infoRemark" autocomplete="off" disabled readonly></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="userInfoOver">保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!--     尾   账户信息弹出窗-->
    <!--     头   修改密码弹出窗-->
    <div class="popup">
      <el-dialog title="修改密码" :visible.sync="resetPassVisible">
        <div class="scroll" ref="scroll">
          <el-form>
            <el-form-item label="新密码" :label-width="formLabelWidth" required>
              <div style="width: 70%">
                <el-input v-model="resetPassReq.password"  show-password placeholder="请输入密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="确认密码"  :label-width="formLabelWidth" required>
              <div style="width: 70%">
                <el-input v-model="confirmPassword" show-password placeholder="请输入密码"></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="confirmHandle">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!--     尾   修改密码弹出窗-->
  </div>
</template>

<script scoped>
import {userInfoOver} from "../api/authorityManage";
import md5 from  '../util/js/md5'
import {login,uploadApi} from "../api/public";

let allRouter,userInfo
  if(localStorage.getItem('PAAS_AllRouter')){
    allRouter = JSON.parse(localStorage.getItem('PAAS_AllRouter'))
    userInfo = JSON.parse(localStorage.getItem('paas_loginInfo')).user //登录接口返回的个人信息
  }
export default {
  name: "Header",
  components:{
  },
  data() {
    return {
      dialogVisible:false,
      allRouter:this.$store.state.userRouter?this.$store.state.userRouter:allRouter,
      userInfo:this.$store.state.loginInfo?this.$store.state.loginInfo.user:userInfo,
      formLabelWidth: '120px',//
      infoDialogVisible:false,//账户信息弹窗控制
      resetPassVisible:false,//密码修改弹窗
      overInfoReq:{ // 完善用户信息请求体
        imagehead:'',
        name:'',
        phone:'',
        sex:'',
        adcode:'',
      },
      formRule:{
        name:[
          {required:true,message:'不能为空',trigger:'blur'}
        ],
        phone:[
          {required:true,message:'不能为空',trigger:'blur'},
          { pattern:  /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/,
            message: '请输入正确手机号码',trigger: 'blur' }
        ]
      },
      resetPassReq:{
        password:'',
      },
      confirmPassword:'',//确认密码
      infoRemark:'',//个人信息身份描述
      //登录账号信息
      loginForm:{
        username:'',
        password:'',
      },
      uploadImagehead:'',//上传临时头像
    };
  },
  props: ["msg"],
  created(){
    // let {name,phone,sex,imagehead,remark,adcode,username,password,hxid,password2} = this.userInfo
    // this.infoRemark=remark;
    // this.loginForm={username,password}
    // this.overInfoReq={name,phone,sex,imagehead,adcode}
    // if(this.$store.state.justLogin&&(!name||!phone||!sex)){
    //   this.$confirm('检测到您的个人信息不完善，是否立即前往完善？', '信息完善', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'info'
    //   }).then(()=>{
    //     this.info()
    //   })
    // }
  },
  methods: {
    //上传营业执照
    uploadHead({file}){
      const formData = new FormData();
      formData.append('file',file)
      uploadApi(formData).then(res=>{
        if(res.code==1000){
          this.uploadImagehead=res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleSelect(key, keyPath) {
    },
    handleCommand(name) {
      if(name=='quit'){
        this.quit()
      }
      if(name=='revise'){
        this.passWork()
      }
      if(name=='info'){
        this.info()
      }
    },
    //密码信息
    passWork(){
      this.resetPassVisible = true
    },
    //修改密码
    confirmHandle(){
      if(!this.resetPassReq.password){
        return this.$message.error('密码不能为空')
      }
      if(!this.confirmPassword){
        return this.$message.error('请输入确认密码')
      }
      if(this.resetPassReq.password!=this.confirmPassword){
        return this.$message.error('密码不一致')
      }
      this.$confirm('确认修改密码后需重新登录，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
          this.resetPassReq.password=md5.hexMD5(this.resetPassReq.password)
          userInfoOver(this.resetPassReq).then(res=>{
            if(res.code==1000){
              this.$message.success('修改成功,请重新登录')
              this.resetPassVisible = false
              this.$store.commit('tokenTimeOut')//退出登录
            }else{
              this.$message.error('修改失败')
            }
          })
      })
    },
    //账号信息
    info(){
      this.infoDialogVisible = true
      this.uploadImagehead=this.overInfoReq.imagehead//上传临时显示
    },
    //信息完善
    userInfoOver(){
      this.$refs['infoForm'].validate((flag)=>{
        if(flag){
           this.$loading({
            lock: true,
            text: '正在保存',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.1)'
          });
          this.overInfoReq.imagehead=this.uploadImagehead
          userInfoOver(this.overInfoReq).then(res=>{
            if(res.code==1000){
              login(this.loginForm).then(res=>{
                if(res.code==1000){
                  this.$loading().close()
                  if(!res.data) return
                  this.$store.commit('loginInfoSave',res.data)
                  localStorage.setItem('paas_loginInfo',JSON.stringify(res.data))
                  localStorage.setItem('PAAS_TOKEN',JSON.stringify(res.data.token))
                  this.userInfo=JSON.parse(localStorage.getItem('paas_loginInfo')).user
                }
                this.infoDialogVisible = false
                this.$message.success('保存成功')
              }).catch(()=>{
                this.$loading().close()
                this.infoDialogVisible = false
                this.$message.error('信息更新异常')
              })
              //修改环信信息资料
              let option = {
                nickname: this.overInfoReq.name,
                avatarurl:this.overInfoReq.imagehead,
                mail: "",
                phone: this.overInfoReq.phone,
                gender: this.overInfoReq.sex,
                birth: "",
                sign: "",
                ext: JSON.stringify({
                  nationality: "China",
                  merit: "Hello, world！",
                }),
              };
              WebIM.conn.updateUserInfo(option).then((res) => {
                console.log(res,'环信信息修改成功');
              });
            }else{
              this.$loading().close()
              this.$message.error('保存失败')
            }
          }).catch(()=>{
            this.$loading().close()
          })
        }else{
          return false
        }
      })
    },
    quit(){
      this.$store.commit('webIMClear')
      this.$store.commit('userClear')
      this.$store.commit('loginInfoClear')
      this.$store.commit('tokenTimeOut')
    }
  },
  watch:{

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello{
    display: flex;
    align-content: space-between;
  }
  .left{
    width: 240px;
    display: flex;
    align-items: center;
  }
  .left>img{
    cursor: pointer;
    /*margin-left: 24px;*/
  }
  .left>span{
    cursor: pointer;
    font-size: 25px;
    font-weight: 900;
    margin-left: 20px;
    background: linear-gradient(90deg, #4444e2,#409eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.el-menu-demo {
  display: flex;
}
.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.right>span{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-left: 14px;
}
.userName {
  font-size: 14px;
  color: rgb(130, 131, 135);
  font-weight: 400;
  /*cursor: pointer*/
}
.rightIcon {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 10px;
}
.rightIcon>img {
  width: 21px;
  height: 25px;
  opacity: 1;
}
.numIcon {
  position: absolute;
  right: -5px;
  top: -3px;
  width: 16px;
  height: 16px;
  opacity: 1;
  background: rgba(246, 70, 86, 1);
  border-radius: 50%;
  border: none;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  outline: none;
  pointer-events: none;
  font-size: 11px;
}
.hello .el-menu {
  padding-left: 64px !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
/*  下拉菜单*/
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-icon--right{
  margin-bottom: 2px;
}
  .tuImg{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding: 0 15px;
    margin-left: 43px;
    cursor: pointer
  }
::v-deep   .el-menu-item{
  padding: 0 !important;
  margin: 0 20px;
}
::v-deep  .el-dropdown-menu .el-dropdown-menu__item{
  padding: 0 20px!important;
}
  .dropdown-li{
    display: flex;
    align-items: center;
  }
  .dropdown-li>span>img{
    display: flex;
    align-items: center;
  }
  .dropdown-li>span:nth-of-type(1){
    margin-right: 10px;
  }
.hello .el-dropdown-menu__item, .el-menu-item{
  font-size: 16px;
}
</style>
<!--聊天界面-->
<style scoped>
  ::v-deep .chatDialog .el-dialog{
    width: 60vw;
    height: 80vh;
    position: absolute;
    top: 0;
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
  }
  ::v-deep  .chatDialog .el-dialog__body{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  ::v-deep  .chatDialog .el-dialog__header,::v-deep  .chatDialog .el-dialog__body{
    padding: 0 !important;
  }
  ::v-deep  .chatDialog .el-dialog__headerbtn .el-dialog__close{
    position: relative;
    z-index: 9999;
  }
</style>
<!--修改功能弹窗-->
<style scoped>
  ::v-deep  .popup .el-dialog{
    width: 30%;
    min-width:600px;
    border-radius: 10px;
    /*margin-top: 10vh !important;*/
  }
  ::v-deep  .popup .el-dialog__title{
    width: 64px;
    height: 24px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
    margin-left: 40px;
    left: -8065px;
    top: -3589px;
  }
  ::v-deep  .popup .el-dialog__title:before{
    content:'';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background:rgba(31, 120, 255, 1)  ;
    position: absolute;
    top: 27px;
    left: 40px;
  }
  ::v-deep  .popup .el-dialog__body{
    padding: 10px 20px;
  }

  ::v-deep  .popup .el-form-item__label{
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    vertical-align: top;
  }
  ::v-deep  .popup .el-form-item{
    margin-bottom: 15px;
  }
  ::v-deep  .popup .el-col-1{
    margin: 0 5px;
    text-align: center;
    color: black;
  }
  ::v-deep  .popup .el-col-2{
    width: 1px;
  }
  ::v-deep  .popup .el-col-11{
    width: 130px;
  }
  ::v-deep  .popup .el-input__prefix{
    display: none;
  }
  ::v-deep  .popup .el-input--suffix .el-input__inner{
    /*height: 31px !important;*/
  }
  ::v-deep  .popup .el-input--suffix .el-input__inner{
    padding: 0 15px;
  }

  ::v-deep  .popup .el-dialog__footer{
    width: 100%;
    position: relative;
    bottom: 10px;
  }

  ::v-deep  .popup  .dialog-footer .el-button:nth-of-type(1){
    background-size: cover;
    border-radius: 5px;
    color: white;
    width: 265px;
    height: 61px;
    opacity: 1;
    background: rgba(31, 120, 255, 1);
    border: 1px solid rgba(31, 120, 255, 1);
  }
  ::v-deep  .popup .dialog-footer .el-button:nth-of-type(2){
    color: rgba(31, 120, 255, 1);
    border-radius: 5px;
    margin-left: 38px;
    width: 205px;
    height: 61px;
    opacity: 1;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(31, 120, 255, 1);
  }
  ::v-deep  .popup .scroll{
    max-height: 60vh;
    overflow-y: auto;
    padding-bottom: 10px;
    box-sizing: border-box;
    position: relative;
  }
  ::v-deep  .popup .scroll .el-input__inner{
    height: 31px;
    line-height: 31px;
  }
  .dialog-footer{
    text-align: center;
  }
  ::v-deep  .popper__arrow::after{
    display: none;
  }
  ::v-deep  .popper__arrow{
    display: none !important;
  }
  ::v-deep  .popup .el-popper{
    margin-top: 0;
  }

  /*  按钮*/
  .btnLabeli>button{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnLabeli img{
    width: 13px;
  }
  .btnLabeli>button:nth-of-type(1){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border: 0.5px solid  rgba(73, 119, 252, 1);
  }
  .btnLabeli>button:nth-of-type(2){
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    border: 0.5px solid rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(73, 119, 252, 1);
  }
/*  上传头像*/
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border: 1px #d2d2d2 dashed;
    border-radius: 50%;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: block;
    border-radius: 50%;
  }

  ::v-deep  .pictureImg .el-icon-plus:before{
    line-height: 0;
  }
</style>
