<template>
    <div class="pageMain">
        <div class="tableMain">
            <div class="tableBox">
                <ETable ref="ETable" :tableData="tableData" @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange">
                    <template #companyinfo>
                        {{ searchCompany.companyname || '--' }}
                    </template>
                    <template #insurancetype="{ rowData: rowData }">
                        {{ rowData?.plan?.insurancetype || '--' }}|{{ rowData?.plan?.insuredamount || '--' }}|{{
                            rowData?.plan?.category || '--'
                        }}
                    </template>
                    <template #video="{ rowData: rowData }">
                        <video v-if="rowData?.plan?.videourl" :src="rowData.plan.videourl" width="57px" height="74px"
                            controls></video>
                        <span v-else>--</span>
                    </template>
                    <template #creator="{ rowData: rowData }">
                        {{ rowData?.user?.name || '--' }}·{{ rowData?.user?.phone }}
                    </template>
                    <template #operate="{ rowData: rowData }">
                        <!-- <el-button type="text" size="small" @click="descVip(rowData)">详情</el-button> -->
                        <el-button v-if="rowData?.plan?.status === '0'" type="text" size="small" @click="onPass(rowData)">审核通过</el-button>
                        <div :class="`status-${rowData?.plan?.status}`" v-else>
                            {{ statusMap.get(rowData?.plan?.status) }}
                        </div>
                    </template>
                </ETable>
            </div>
        </div>
    </div>
</template>

<script>
// 方案审核状态0待审核1通过（进行中）2审核驳回3停用
import { getPlanList, getVipGradeList, alterVipAccEd, auditPlan } from "@/api/workbenche";
import { pcToAuthenticate } from "@/api/public";
import moment from "@/util/js/moment";
const statusMap = new Map([
    ['0', '待审核'],
    ['1', '通过'],
    ['3', '停用'],
])
export default {
    name: "end",
    data() {
        return {
            statusMap: statusMap,
            //表格列表请求体
            listReq: {
                index: 20,
                page: 1,
                data: {
                    status: ['0'],
                    createtime: [],
                },
                userid: ''
            },
            searchCompany: {},
            //过滤数据
            list: {
                stateList: [
                    { label: '停用', value: 0 },
                    { label: '启用', value: 1 },
                    // {label:'待审核',value:2}
                ]
            },
            tableData: {
                serial: 0,
                operate: 0,
                currentPage: 0,
                data: [],
                cols: [
                    { label: '客户公司名称', prop: 'companyinfo' },
                    { label: '工种', prop: 'plan.jobtype' },
                    { label: '投保方案', prop: 'insurancetype' },
                    { label: '用工单位', prop: 'plan.companyname' },
                    { label: '单位地址', prop: 'plan.companyadress' },
                    { label: '工作视频', prop: 'video' },
                    { label: '创建人', prop: 'creator' },
                    { label: '操作', prop: 'operate' },
                    
                ],
            },
            //详情、修改控制
            // readonlyFlag: false,
        };
    },
    created() {
        this.$ELoading();
        if (!this.$parent.companyList.length) {
            this.$parent.addGetList();
        } else {
            if (!this.listReq.userid) {
                this.$parent.searchForm.companyid = this.$parent.companyList[0].id;
                this.$parent.searchForm.selectedCompany = this.$parent.companyList[0];
                this.listReq.userid = this.$parent.companyList[0].id;
            }
            this.getList().then(() => {
                this.$EClose()
            })
        }
    },
    methods: {
        //获取列表
        getList() {
            if (this.listReq.data.status.find(i => ['0'].includes(i))) {
                this.tableData.cols[7].label = '操作';
            } else {
                this.tableData.cols[7].label = '状态';
            }
            console.log('2323', this.listReq.data.createtime)
            return new Promise(resolve => {
                if (this.listReq.data.createtime?.length > 0) {
                    this.listReq.data.createtime = [moment(this.listReq.data.createtime[0]).format('YYYY-MM-DD 00:00:00'), moment(this.listReq.data.createtime[1]).format('YYYY-MM-DD 23:59:59')]
                } else {
                    this.listReq.data.createtime = [];
                }
                console.log('req----============', {...this.listReq.data})
                this.searchCompany = this.$parent.searchForm.selectedCompany;
                getPlanList(this.$req(this.listReq)).then(res => {
                    if (res.code) {
                        resolve()
                        if (res.code == 1000) {
                            this.tableData.data = res.data;
                            console.log('this.tableData', this.tableData)
                        } else {
                            this.$message.error(res.message ? res.message : '操作失败')
                        }
                    }
                })
            })
        },
        //详情
        descVip(item) {
            this.readonlyFlag = true
            this.alterVip(item, 'descVip')
        },
        //搜索
        searchEndBtn() {
            this.$ELoading()
            this.getList().then(() => {
                this.$EClose()
            })
        },
        onPass(item) {
            console.log('item', item);
            this.$confirm('是否将该方案审核通过，通过后数据流转至已审核', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$ELoading();
                auditPlan({
                    id: item.plan.id,
                    status: '1',
                    userid: this.listReq.userid || this.searchCompany.id
                }).then((res) => {
                    console.log('res', res);
                    if (res.code == 1000) {
                        this.$message.success(res.message);
                        this.getList();
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$EClose()
                })
            })

        },
        handleSizeChange(val) {
            this.listReq.index = val
            this.listReq.page = 1
            this.$iApi.paging(this.getList).then(() => {
                this.$EClose()
            })
        },
        handleCurrentChange(val) {
            this.listReq.page = val
            this.$iApi.paging(this.getList).then(() => {
                this.$EClose()
            })
        },
    },
};
</script>

<style scoped>
.pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
}

.search_form {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    flex: 1;
}

.fromLabels {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

::v-deep .fromLabels .el-form-item {
    margin-bottom: 0;
}

::v-deep .el-input__inner {
    height: 31px;
    line-height: 31px;
}

::v-deep .el-select .el-input .el-select__caret {
    line-height: 31px;
}

::v-deep .el-select .el-input .el-select__caret {
    color: rgba(100, 102, 107, 1);
}

.label {
    position: relative;
    margin-right: 10px;
}

.btnLabel {
    width: 200px;
    height: 35px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 36px;
}

.btnLabel>button:hover {
    cursor: pointer;
}

.btnLabel>button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.btnLabel img {
    width: 13px;
}

.btnLabel>button:nth-of-type(1) {
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(73, 119, 252, 1);
}

.btnLabel>button:nth-of-type(2) {
    width: 86px;
    height: 35px;
    opacity: 1;
    border-radius: 7px;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(73, 119, 252, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(73, 119, 252, 1);
}

.tableMain {
    width: 100%;
    height: 100%;
    background: #f5f6fa;
    box-sizing: border-box;
}

.tableBox {
    width: 100%;
    height: 100%;
    background: white;
}

::v-deep .el-table tr {
    background: white;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}

::v-deep .el-table .sort-caret.ascending {
    border-bottom-color: #409eff;
    top: 5px;
}

::v-deep .el-table .sort-caret.descending {
    border-top-color: #409eff;
    bottom: 7px;
}

::v-deep .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #c0c4cc;
}

::v-deep .el-table .descending .sort-caret.descending {
    border-top-color: #c0c4cc;
}

/*分页*/
.paging {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

::v-deep .paging .el-input__inner {
    padding: 0 15px !important;
}

::v-deep .scroll .el-table .el-table__cell {
    padding: 4px 0 !important;
}

/*表格最后一列小白块处理*/
::v-deep .el-table th.el-table__cell {
    background: rgb(245, 246, 250);
}
.status-1 {
        
        color: #43CF7C;
    }

    .status-2,
    .status-4 {
        color: #FF8D1A;
        
    }

    .status-3 {
        color: #FF5733;
    }
</style>
