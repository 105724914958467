<template>
    <div class="dictTree">
        <div class="padding">
            <el-tree
                    :props="treeProps"
                    node-key="id"
                    :load="loadNode"
                    lazy
                    @node-collapse="nodeCollapse"
                    :default-expand-all="false"
                    :expand-on-click-node="true">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="primary" plain size="mini" @click.capture.stop="() => append(node,data)">
                    添加
                  </el-button>
                    <el-button type="success" plain size="mini" @click.capture.stop="() => modify(node,data)">
                    编辑
                  </el-button>
                  <el-button type="warning" :disabled="data.id==0" plain size="mini" v-if="data.state==2" @click.capture.stop="() => open(node, data)">
                    启用
                  </el-button>
                  <el-button type="danger" :disabled="data.id==0" plain size="mini" v-else @click.capture.stop="() => remove(node, data)">
                    禁用
                  </el-button>
                </span>
              </span>
            </el-tree>
        </div>
    </div>
</template>
<script scoped>
    import {AddDictOne,AltDictOne,getDictList} from '../../../../api/dict'
    let id = 1000;
    export default {
        data(){
            return{
                treeProps:{
                    label:'name',
                    children:'detail',
                    isLeaf: 'leaf'
                },
            }
        },
        created() {

        },
        methods:{
            //收起列表时，再次展开重新开启加载
            nodeCollapse(data , node) {
                node.loaded=false
                node.expanded=false
            },
            //动态加载数
            loadNode(node, resolve) {
                if (node.level === 0) {
                    return resolve([{ name: '字典库',id:0}]);
                }else{
                    if(node.level===1){
                        this.getDictList(0).then(res=>{
                            resolve(res.data)
                        })
                    }else{
                        this.getDictList(node.data.id).then(res=>{
                            resolve(res.data)
                        })
                    }
                }
            },
            //获取段落列表
            getDictList(id){
                return new Promise((resolve,reject) => {
                    getDictList(id).then(res=>{
                        if(res.code){
                            if(res.code==1000){
                                resolve(res)
                            }else{
                                this.$message.error(res?.message)
                            }
                        }else{
                            reject(res)
                        }
                    })
                })
            },
            //字典添加
            append(node,data) {
                this.$prompt('添加命名：', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^(?!\s*$).+/,
                    inputErrorMessage: '不允许为空',
                }).then(({ value }) => {
                    this.$ELoading('','正在添加')
                    const {id} = data
                    AddDictOne({name:value,parentid:id}).then(res=>{
                        this.$EClose()
                        if(res.code==1000){
                            node.loaded=false
                            node.expanded=false
                            this.$message.success('添加成功')
                        }else{
                            this.$message.warning('添加失败')
                        }
                    })
                })
            },
            //字典编辑
            modify(node,data){
                const {name,id} = data
                this.$prompt('编辑命名：', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^(?!\s*$).+/,
                    inputErrorMessage: '不允许为空',
                    inputValue:name,//初始值
                }).then(({ value }) => {
                    this.$ELoading('','正在保存')
                    AltDictOne({name:value,id}).then(res=>{
                        this.$EClose()
                        if(res.code==1000){
                            node.parent.loaded=false
                            node.parent.expanded=false
                            this.$message.success('编辑成功')
                        }else{
                            this.$message.warning('编辑失败')
                        }
                    })
                })
            },
            //启用
            open(node,data){
                this.$confirm('即将启用该条数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ELoading('','正在加载')
                    const {id} = data
                    AltDictOne({id,state:1}).then(res=>{
                        this.$EClose()
                        if(res.code==1000){
                            node.parent.loaded=false
                            node.parent.expanded=false
                            this.$message.success('删除成功')
                        }else{
                            this.$message.warning('删除失败')
                        }
                    })
                })
            },
            //字典禁用
            remove(node, data) {
                this.$confirm('即将禁用该条数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ELoading('','正在加载')
                    const {id} = data
                    AltDictOne({id,state:2}).then(res=>{
                        this.$EClose()
                        if(res.code==1000){
                            node.parent.loaded=false
                            node.parent.expanded=false
                            this.$message.success('删除成功')
                        }else{
                            this.$message.warning('删除失败')
                        }
                    })
                })
            },
        }
    }
</script>
<style scoped>
    .dictTree{
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dictTree>.padding{
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        background: white;
        overflow-y: auto;
    }
    .custom-tree-node{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .el-button--mini, .el-button--mini.is-round{
        padding: 5px 15px;
    }
    ::v-deep .el-tree-node__content{
        height: 30px;
    }
</style>
