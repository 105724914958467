<template>
    <div class="pageMain">
        <div class="tableMain">
            <ETable ref="ETable" :tableData="tableData" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange">
                <template #status="{ rowData: rowData }">
                    <div :class="`status-${rowData.insurancestatus}`">
                        {{ insurancestatusMap.get(rowData.insurancestatus) }}
                    </div>
                </template>
                <template #operate="{ rowData: rowData }">
                    <el-button type="text" size="small" @click="detailService(rowData)">详情</el-button>
                    <el-button type="text" size="small" @click="editService(rowData)">查看账户</el-button>
                    <el-button v-if="['2'].includes(rowData.insurancestatus)" type="text" size="small"
                        @click="resubmitService(rowData)">重新提交</el-button>
                </template>
            </ETable>
        </div>
        <!-- 查看账户 -->
        <editService ref="editService" type="edit"></editService>
        <!-- 详情 -->
        <detailService ref="detailService" type="detail"></detailService>
        <!-- 重新提交 -->
        <resubmitService ref="resubmitService" type="resubmit"></resubmitService>
    </div>
</template>

<script>
import { getCompanyList, getUserList, openInsuranceDetail } from "@/api/workbenche";
//   微保开通状态1审核中2审核拒绝3审核通过4审核失败(默认0未开通)  
const insurancestatusMap = new Map([
    ['1', '审核中'],
    ['2', '审核未通过'],
    ['3', '正常'],
    ['4', '审核失败']
])
export default {
    name: "end",
    components: {
        editService: () => import('./component/addSvs'),
        detailService: () => import('./component/addSvs'),
        resubmitService: () => import('./component/addSvs'),
    },
    data() {
        return {
            //store
            storeIf: false,//商家管理
            //表格列表请求体
            listReq: {
                index: 20,
                page: 1,
                data: {
                    companyname: '',
                }
            },
            tableData: {
                serial: 0,
                currentPage: 0,
                data: [],
                cols: [
                    { label: '姓名', prop: 'name' },
                    { label: '已开通手机号', prop: 'phone' },
                    { label: '联系电话', prop: 'userphone' },
                    { label: '公司名称', prop: 'companyname' },
                    { label: '对公账户', prop: 'bankno' },
                    { label: '状态', prop: 'status' },
                ],
            },
            insurancestatusMap: insurancestatusMap
        };
    },
    created() {
        this.$ELoading()
        this.getList().then(() => {
            this.$EClose()
        })
    },
    methods: {
        //获取列表
        getList() {
            return new Promise(resolve => {
                getCompanyList(this.$req(this.listReq)).then(res => {
                    if (res.code) {
                        resolve()
                        if (res.code == 1000) {
                            this.tableData.data = res.data
                        } else {
                            this.$message.error(res.message ? res.message : '操作失败')
                        }
                    }
                })
            })
        },
        resubmitService(item) {
            this.editService(item, 'resubmitService')
        },
        //详情
        detailService(item) {
            this.editService(item, 'detailService')
        },
        //编辑
        editService(item, name = 'editService') {
            console.log('item', item)
            // 详情/再次提交
            if (name === 'detailService' || name === 'resubmitService') {

                this.$refs[name].show = true;
                getUserList({
                    data: {
                        status: '1',
                        insurancestatus: 0
                    }
                }).then(res=>{
                    console.log('res', res.data)
                    if(res.code){
                        if(res.code==1000){
                            const companys = res.data.list.map(i => i.data1);
                            this.$refs[name].form.id = [item?.id];
                            let selectedCompany = companys.find(i => i.id === item?.id);
                            if (!selectedCompany) {
                                selectedCompany = {
                                    id: item.id,
                                    name: item.name,
                                    userphone: item.userphone,
                                    registeraddress: item.registeraddress,
                                    companyname: item.companyname
                                }
                                companys.push(selectedCompany);
                                this.$refs[name].baseList.companyList = companys;
                                console.log('this.$refs[name].baseList.companyList', this.$refs[name].baseList.companyList)
                            }
                            this.$refs[name].baseList.selectedCompany = selectedCompany;
            
                            this.$refs[name].form.taxpayerid = item?.taxpayerid;
                            this.$refs[name].form.bankno = item?.bankno;
                            this.$refs[name].form.depositbank = item?.depositbank;
                            this.$refs[name].form.insurancetype = item && item.insurancetype && item.insurancetype.split('|') || [];

                            this.$nextTick(() => {
                                if (name === 'detailService') {
                                    this.$refs[name].$refs['ruleForm'].clearValidate()
                                }
                            })
                        }else{
                            this.$EClose()
                            this.$message.error('操作失败')
                        }
                    }
                })
            } else {
                //查看账户
                openInsuranceDetail({
                    id: item.id
                }).then((res) => {
                    console.log('res', res.data)
                    if (['1', '2'].includes(res.data.insurancestatus)) {
                        this.$message.error(res.message);
                        return;
                    }
                    this.$refs[name].show = true
                    this.$nextTick(() => {
                        this.$refs[name].form.id = [item?.id]
                        this.$refs[name].form.taxpayerid = item?.taxpayerid;
                        this.$refs[name].form.bankno = item?.bankno;
                        this.$refs[name].form.depositbank = item?.depositbank;
                        this.$refs[name].form.insurancetype = item && item.insurancetype && item.insurancetype.split('|') || [];
                        this.$refs[name].form.insuranceaccount = res?.data?.insuranceaccount;
                        this.$nextTick(() => {
                            this.$refs[name].$refs['ruleForm'].clearValidate()
                        })
                    })
                })
            }
        },
        handleSizeChange(val) {
            this.listReq.index = val
            this.listReq.page = 1
            this.$iApi.paging(this.getList).then(() => {
                this.$EClose()
            })
        },
        handleCurrentChange(val) {
            this.listReq.page = val
            this.$iApi.paging(this.getList).then(() => {
                this.$EClose()
            })
        },
    },
};
</script>

<style scoped lang="scss">
.pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
}

.tableMain {
    width: 100%;
    height: 100%;

    .status-1 {
        color: #FF8D1A;
    }

    .status-2,
    .status-4 {
        color: #FF5733;
    }

    .status-3 {
        color: #43CF7C;
    }
}
</style>
