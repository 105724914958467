import { render, staticRenderFns } from "./alterVip.vue?vue&type=template&id=186316e4&scoped=true"
import script from "./alterVip.vue?vue&type=script&lang=js"
export * from "./alterVip.vue?vue&type=script&lang=js"
import style0 from "./alterVip.vue?vue&type=style&index=0&id=186316e4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186316e4",
  null
  
)

export default component.exports