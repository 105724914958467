import request from "../util/require";

/**
 * 获取段落字典列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDictList(parentid,state) {
    return request({
        url:'/paasbase/system/dictionary/list',
        method:'post',
        data:{parentid,state}
    })
}
/**
 * 字典添加
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function AddDictOne(data) {
    return request({
        url:'/paasbase/system/dictionary/save',
        method:'put',
        data
    })
}

/**
 * 字典修改、删除
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function AltDictOne(data) {
    return request({
        url:'/paasbase/system/dictionary/modify',
        method:'post',
        data
    })
}
