<template>
    <div class="yiyongbao">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "yiyongbo.vue"
    }
</script>

<style scoped>
.yiyongbao{
    width: 100%;
    height: 100%;
}
</style>
